import axios from 'axios';
import { connect } from 'react-redux';
import store from '../store';
import jwt_decode from 'jwt-decode';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE,
});

api.interceptors.request.use(async config => {
  if (config.url !== "/Auth/login") {
    const token = localStorage.getItem("token");;
    if (token) {
      var decoded = jwt_decode(token);
      var current_time = new Date().getTime() / 1000;
      if (current_time > decoded.exp) {
        localStorage.removeItem('token');
        window.location.replace("/login");
      }
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      window.location.replace("/login");
    }
  }
  return config;
});

api.interceptors.response.use((response) => {
  const { dispatch } = store;
  dispatch({
    type: 'ADD_ERRO_MENSAGEM',
    error: {
      tipo: 'alert-success',
      hideMessage: false,
      errorMessage: [] 
    }
  });
  return response;
}, (error) => {
  const { dispatch } = store;
  if (!error.response) {
    dispatch({
      type: 'ADD_ERRO_MENSAGEM',
      error: {
        tipo: 'alert-danger',
        hideMessage: true,
        errorMessage: [{ mensagem: "Sem comunicação com o servidor!" }]
      }
    })
  } else {    
    if (error.response.status === 401) {
      window.location.replace("/login");
    }
    if (error.response.status === 403) {
      window.location.replace("/#");
    }
    if (error.response.status === 400 && error.response.data.errors) {
      dispatch({
        type: 'ADD_ERRO_MENSAGEM',
        error: {
          tipo: 'alert-danger',
          hideMessage: true,
          errorMessage: [{ mensagem: "Erro interno! Contate o suporte." }]
        }
      })
      return;
    }
    dispatch({
      type: 'ADD_ERRO_MENSAGEM',
      error: {
        tipo: 'alert-danger',
        hideMessage: true,
        errorMessage: error.response.data
      }
    })
  }
});

export default connect()(api);