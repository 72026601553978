import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import { mask, unMask } from 'remask';
import Erros from '../../../components/Erro';

function EditarComprador() {
    let history = useHistory();
    let { id, idCliente } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [comprador, setComprador] = useState({ telefone: "" });
    const [cliente, setCliente] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await api.get('/Cliente/id/' + idCliente).then(response => {
                setCliente(response ? response.data : null);
            });

            await api.get('/Comprador/id/' + id).then(response => {
                setComprador(response ? response.data : null);
                setComprador(prev => ({ ...prev, telefone: mask(unMask(response.data.telefone), '(99) 99999-9999')}));
            });
        }
        fetchData();
    }, [id, idCliente]);
    
    const onChangeMask = (event, maskFormat) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setComprador(prev => ({ ...prev, [name]: mask(unMask(value), maskFormat) }));        
    }    

    const onSubmit = async data => {
        window.scrollTo(0, 0);      
        data.telefone = unMask(data.telefone);
        await api.put('/Comprador', data).then(response => {
            if (response) {
                history.push(`/compradores/${idCliente}`)
            }
        });
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">
                    <h5>{cliente ? cliente.nome : ""}</h5>
                    Compradores
                </div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Editar Comprador</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" defaultValue={id} name="id" ref={register} />
                        <input type="hidden" defaultValue={idCliente} name="clienteId" ref={register} />
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Nome</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="nome" id="Nome" defaultValue={comprador ? comprador.nome : ""} ref={register({ required: true })} placeholder="Nome" />
                                    {errors.nome && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Telefone</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="telefone" id="telefone" value={comprador ? comprador.telefone : ""} onChange={(e) => onChangeMask(e, ['(99) 99999-9999'])} ref={register} placeholder="Telefone" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">E-Mail</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="email" id="email" defaultValue={comprador ? comprador.email : ""} ref={register} placeholder="E-Mail" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Aniversário</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="aniversario" id="aniversario" defaultValue={comprador ? comprador.aniversario : ""} ref={register} placeholder="Aniversário" />
                                </div>
                            </div>                            
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to={`/compradores/${idCliente}`}>Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarComprador;