import React, { Component } from 'react';
import api from '../../services/api';
import Logo from '../../assets/logo.png';
import { connect } from 'react-redux';
import Erros from '../../components/Erro'

class Login extends Component {
    state = {
        email: "",
        senha: ""
    }

    handleSubmit = async e => {
        e.preventDefault();
        api.post("/Auth/login", this.state).then(response => {
            if (response) {
                const { dispatch } = this.props;
                dispatch({
                    type: 'ADD_ERRO_MENSAGEM',
                    error: {
                        tipo: '',
                        hideMessage: false,
                        errorMessage: []
                    }
                })
                localStorage.setItem('token', response.data);
                window.location.replace("/");
            }
        });
    }

    handleInputEmailcpf = e => {
        this.setState({ email: e.currentTarget.value });
    }

    handleInputSenha = e => {
        this.setState({ senha: e.currentTarget.value })
    }
    render() {
        const { login, senha } = this.state;
        return (
            <>
                <div className="bg-dark headerLogin">
                    <img className='logo' src={Logo} alt="Logo" />
                </div>
                <div className="container" style={{ marginTop: 20 }}>
                    <div className="row justify-content-md-center">
                        <div className="col-5">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label>E-Mail</label>
                                    <input type="email" className="form-control" id="email" name="email" onChange={this.handleInputEmailcpf} value={login} aria-describedby="emailHelp" placeholder="E-Mail" />
                                </div>
                                <div className="form-group">
                                    <label>Senha</label>
                                    <input type="password" className="form-control" id="senha" name="senha" value={senha} onChange={this.handleInputSenha} placeholder="Senha" />
                                </div>
                                <Erros />
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block">Entrar</button>
                                </div>
                            </form>
                        </div>
                    </div >
                </div>
            </>
        );
    }
}

export default connect(state => ({
    error: state.header
}))(Login);
