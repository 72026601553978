import React, { Component } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { parseISO, format } from 'date-fns';
import { Link } from 'react-router-dom';
import Erros from '../../components/Erro';
import NovoPedido from './novoPedido';

class Pedido extends Component {
    state = {
        numeroPedido: localStorage.getItem('numeroPedido') ?? 0,
        dataInicio: localStorage.getItem('dataInicio') ?? "",
        dataFim: localStorage.getItem('dataFim') ?? "",
        clienteId: localStorage.getItem('clienteId') ?? "",
        fornecedorId: localStorage.getItem('fornecedorId') ?? "",
        statusPedido: localStorage.getItem('statusPedido') ?? "",
        pedidos: [],
        clientes: [],
        fornecedores: []
    }

    async componentDidMount() {
        await api.get(`/Cliente/todos`).then(response => {
            this.setState({ clientes: response ? response.data : null });
        });
        await api.get(`/Fornecedor/todos`).then(response => {
            this.setState({ fornecedores: response ? response.data : null });
        });
    }

    handleInput = e => {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
    }

    handleLocalizar = async e => {
        e.preventDefault();
        var url = `/Pedido/obterFiltro?`;
        url = this.state.numeroPedido ? `${url}&numeroPedido=${this.state.numeroPedido}` : `${url}&numeroPedido=0`
        url = `${url}&dataInicio=${this.state.dataInicio}`
        url = `${url}&dataFim=${this.state.dataFim}`
        url = `${url}&clienteId=${this.state.clienteId}`
        url = `${url}&fornecedorId=${this.state.fornecedorId}`
        url = `${url}&statusPedido=${this.state.statusPedido}`

        await api.get(url).then(response => {
            this.setState({ pedidos: response ? response.data : null });
        });

        localStorage.setItem('numeroPedido', this.state.numeroPedido);
        localStorage.setItem('dataInicio', this.state.dataInicio);
        localStorage.setItem('dataFim', this.state.dataFim);
        localStorage.setItem('clienteId', this.state.clienteId);
        localStorage.setItem('fornecedorId', this.state.fornecedorId);
        localStorage.setItem('statusPedido', this.state.statusPedido);
    }

    handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete(`Pedido?id=${id}`).then(response => {
                if (response) {
                    this.setState({ pedidos: this.state.pedidos.filter(g => g.id !== id) });
                }
            });
        }
    }

    render() {
        const { dataInicio, dataFim, statusPedido, clienteId, fornecedorId, pedidos, clientes, fornecedores, numeroPedido } = this.state;
        return (
            <div>
                <div className="container" style={{ marginTop: 20 }}>
                    <div className="card">
                        <div className="card-header">Pedidos</div>
                        <Erros />
                        <div className="card-body">
                            <h5 className="card-title">Localizar</h5>
                            <div>
                                <form onSubmit={this.handleLocalizar}>
                                    <div className="form-row">
                                        <div className="col-md-2 mt-1">
                                            <input type="txt" name="numeroPedido" value={numeroPedido} onChange={this.handleInput} className="form-control" id="numeroPedido" placeholder="Número do Pedido" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4 mt-3">
                                            <select id="statusPedido" name="statusPedido" value={statusPedido} onChange={this.handleInput} className="form-control" >
                                                <option value="">Status</option>
                                                <option value="1">Cadastrado</option>
                                                <option value="2">Em Produção</option>
                                                <option value="3">Entregue</option>
                                                <option value="4">Cobrar Comissão</option>
                                                <option value="5">Finalizado</option>
                                                <option value="6">Cancelado</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                            <input type="date" name="dataInicio" onChange={this.handleInput} value={dataInicio} className="form-control" id="inputInicial" placeholder="Data Inicial" />
                                        </div>
                                        <div className="col-md-2 mt-3">
                                            <input type="date" name="dataFim" onChange={this.handleInput} value={dataFim} className="form-control" id="inputFinal" placeholder="Data Final" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-4 mt-3">
                                            <select id="clienteId" name="clienteId" value={clienteId} onChange={this.handleInput} className="form-control">
                                                <option value="">Cliente</option>
                                                {
                                                    clientes && clientes.map((cliente, index) => (
                                                        <option key={index} value={cliente.id}>{cliente.nome}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <select id="fornecedorId" name="fornecedorId" value={fornecedorId} onChange={this.handleInput} className="form-control">
                                                <option value="">Fornecedor</option>
                                                {
                                                    fornecedores && fornecedores.map((fornecedor, index) => (
                                                        <option key={index} value={fornecedor.id}>{fornecedor.razaoSocial}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col-md-1 mt-3">
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                                <Link className="btn btn-success  mx-sm-4 ml-2 mb-2" to={`/pedidos/novo`}>Novo</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="table-responsive mt-3">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nº</th>
                                                <th scope="col">Nº Pedido Cliente</th>
                                                <th scope="col">Data Compra</th>
                                                <th scope="col">Cliente</th>
                                                <th scope="col">Fornecedor</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pedidos && pedidos.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.numero}</td>
                                                    <td>{item.numeroPedidoCliente}</td>
                                                    <td>
                                                        {
                                                            format(parseISO(item.dataCompra), 'dd/MM/yyyy')
                                                        }
                                                    </td>
                                                    <td>{item.cliente.nome}</td>
                                                    <td>{item.fornecedor.razaoSocial}</td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <div style={{ display: "inline-flex" }}>
                                                            <Link className="mx-sm-1 ml-2 mb-2" to={`/pedidos/${item.id}`}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                                            <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => this.handleExcluir(item.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Pedido, NovoPedido };