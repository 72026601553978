import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../services/api';
import { useHistory } from "react-router-dom";
import Erros from '../../components/Erro';
import { mask, unMask } from 'remask';

function NovoFornecedor() {
    let history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [cep, setCep] = useState("");
    const [cnpj, setCnpj] = useState("");

    const onSubmit = async data => {
        window.scrollTo(0, 0);
        data.cep = unMask(data.cep);
        data.cnpj = unMask(data.cnpj);
        await api.post('/Fornecedor', data).then(response => {
            if (response) {
                history.push("/fornecedores")
            }
        });
    };

    const onChangeMask = (value, maskFormat, setMethod) => {
        setMethod(mask(unMask(value), maskFormat));
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">Fornecedores</div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Novo Fornecedor</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Razão Social</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="razaoSocial" id="Nome" ref={register({ required: true })} placeholder="Razão Social" />
                                    {errors.razaoSocial && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">CNPJ/CPF</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="cnpj" value={cnpj} onChange={(e) => onChangeMask(e.target.value, e.target.value.length <= 14 ? ['999.999.999-99'] : ['99.999.999/9999-99'], setCnpj)} id="cnpj" ref={register} placeholder="CNPJ ou CPF" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Rua</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="rua" id="rua" ref={register} placeholder="Rua" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Número</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="numero" id="numero" ref={register} placeholder="Número" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Complemento</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="complemento" id="complemento" ref={register} placeholder="Complemento" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">CEP</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="cep" value={cep} onChange={(e) => onChangeMask(e.target.value, ['99999-999'], setCep)} id="cep" ref={register} placeholder="CEP" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Cidade</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="cidade" id="cidade" ref={register} placeholder="Cidade" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">UF</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="uf" id="uf" ref={register} placeholder="UF">
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">Distrito Federal</option>
                                        <option value="ES">Espírito Santo</option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">Mato Grosso do Sul</option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraíba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">Rio de Janeiro</option>
                                        <option value="RN">Rio Grande do Norte</option>
                                        <option value="RS">Rio Grande do Sul</option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">Santa Catarina</option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantins</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to="/fornecedores">Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NovoFornecedor;