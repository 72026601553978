import React from 'react';
import { connect } from 'react-redux';
import store from '../../store';

function Erros({ errorMessage }) {

    const closeMenssage = () => {
        const { dispatch } = store;
        dispatch({
            type: 'ADD_ERRO_MENSAGEM',
            error: {
                tipo: '',
                hideMessage: false,
                errorMessage: []
            }
        });
    }

    return (
        <>
            {
                errorMessage.hideMessage && (
                    <div className="container mt-2">
                <div className={`alert ${errorMessage.tipo} alert-dismissible fade show`} role="alert">
                            {window.scrollTo(0, 0)}
                            {
                                errorMessage.errorMessage.map((item, index) => (
                                    <p key={index}>{item.mensagem}</p>
                                ))
                            }
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true" onClick={closeMenssage}>&times;</span>
                            </button>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default connect(state => ({
    errorMessage: state.errorMessage,
}))(Erros);