import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import store from '../../../store';
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../../components/Erro';

function NovoNaturezaFinanceira() {
    let history = useHistory();
    let { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [naturezaFinanceira, setNaturezaFinanceira] = useState({});

    useEffect(() => {
        async function fetchData() {
            if (id) {
                await api.get(`/NaturezaFinanceira/id/${id}`).then(response => {
                    setNaturezaFinanceira(response ? response.data : null);
                });
            }
        }
        fetchData();
    }, [id])

    const successMenssage = () => {
        const { dispatch } = store;
        dispatch({
            type: 'ADD_ERRO_MENSAGEM',
            error: {
                tipo: 'alert-success',
                hideMessage: true,
                errorMessage: [{ mensagem: 'Registro Salvo com sucesso!' }]
            }
        });
    }

    const onSubmit = async data => {   
        data.tipo = parseInt(data.tipo);     
        if (!naturezaFinanceira.id) {
            await api.post('/NaturezaFinanceira', data).then(response => {
                if (response) {
                    setNaturezaFinanceira(response.data);
                    history.push(`/NaturezaFinanceira/${response.data.id}`)
                    successMenssage();
                }
            });
        } else {
            data.id = naturezaFinanceira.id;
            await api.put('/NaturezaFinanceira', data).then(response => {
                if (response) {
                    setNaturezaFinanceira(response.data);
                    successMenssage();
                }
            });
        }
    };

    function handleTipoChange(event) {
        const target = event.target;
        const value = parseInt(target.value);
        setNaturezaFinanceira(prevNaturezaFinanceira => ({ ...prevNaturezaFinanceira, tipo: value }));
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {!naturezaFinanceira.id ? 'Nova Natureza Financeira' : `Editar Natureza Financeira`}
                    </div>
                    <Erros />
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Descrição</label>
                                        <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                            <input type="text" className="form-control" name="descricao" id="descricao" defaultValue={naturezaFinanceira.descricao} ref={register({ required: true })} placeholder="Descrição" />
                                            {errors.descricao && <span className="text-danger ml-2">*</span>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <legend className="col-form-label col-sm-2 pt-0">Tipo</legend>
                                        <div className="col-sm-10">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="tipo" ref={register} id="tipo" value='1' onChange={handleTipoChange} checked={naturezaFinanceira.tipo === 1}  required />
                                                <label className="form-check-label">Despesa</label>
                                            </div>     
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="tipo" ref={register} id="tipo" value='2' onChange={handleTipoChange} checked={naturezaFinanceira.tipo === 2} required />
                                                <label className="form-check-label">Receita</label>
                                            </div>                                              
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-2 pt-2">
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="submit" className="btn btn-primary mb-2 mt-1">Salvar</button>
                                                <Link className="btn btn-danger mx-sm-3 ml-2 mb-2 mt-1" to={`/naturezaFinanceira`}>Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default NovoNaturezaFinanceira;