import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import { mask, unMask } from 'remask';
import Erros from '../../../components/Erro';

function EditarLoja() {
    let history = useHistory();
    let { id, idCliente } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [loja, setLoja] = useState({ cnpj: "", cep: "" });
    const [cliente, setCliente] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await api.get('/Cliente/id/' + idCliente).then(response => {
                setCliente(response ? response.data : null);
            });

            await api.get('/Loja/id/' + id).then(response => {
                setLoja(response ? response.data : null);
                setLoja(prev => ({ ...prev, cnpj: mask(unMask(response.data.cnpj), '99.999.999/9999-99'), 
                                            cep: mask(unMask(response.data.cep), '99999-999')}));
            });
        }
        fetchData();
    }, [id, idCliente]);
    
    const onChangeMask = (event, maskFormat) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setLoja(prev => ({ ...prev, [name]: mask(unMask(value), maskFormat) }));        
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setLoja(prev => ({ ...prev, [name]: value }));
    }

    const onSubmit = async data => {
        window.scrollTo(0, 0);        
        data.cep = unMask(data.cep);
        data.cnpj = unMask(data.cnpj);
        data.numeroFilial = parseInt(data.numeroFilial);
        await api.put('/Loja', data).then(response => {
            if (response) {
                history.push(`/lojas/${idCliente}`)
            }
        });
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">
                    <h5>{cliente ? cliente.nome : ""}</h5>
                    Lojas
                </div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Editar Loja</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" defaultValue={id} name="id" ref={register} />
                        <input type="hidden" defaultValue={idCliente} name="clienteId" ref={register} />
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Razão Social</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="razaoSocial" id="Nome" defaultValue={loja ? loja.razaoSocial : ""} ref={register({ required: true })} placeholder="Razão Social" />
                                    {errors.razaoSocial && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">CNPJ</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="cnpj" id="cnpj" value={loja ? loja.cnpj : ""} onChange={(e) => onChangeMask(e, ['99.999.999/9999-99'])} ref={register} placeholder="CNPJ" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Número Filial</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="number" className="form-control" name="numeroFilial" id="numeroFilial"  defaultValue={loja ? loja.numeroFilial : ""} ref={register} placeholder="Número Filial" />
                                    {errors.numeroFilial && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Rua</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="rua" id="rua" defaultValue={loja ? loja.rua : ""} ref={register} placeholder="rua" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Número</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="numero" id="numero" defaultValue={loja ? loja.numero : ""} ref={register} placeholder="Número" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Complemento</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="complemento" id="complemento" defaultValue={loja ? loja.complemento : ""} ref={register} placeholder="Complemento" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">CEP</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="cep" id="cep" value={loja ? loja.cep : ""} onChange={(e) => onChangeMask(e, ['99999-999'])} ref={register} placeholder="CEP" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Cidade</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="cidade" id="cidade" defaultValue={loja ? loja.cidade : ""}  ref={register} placeholder="Cidade" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">UF</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="uf" id="uf" value={loja ? loja.uf : ""} onChange={handleInputChange}  ref={register} placeholder="UF">
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">Distrito Federal</option>
                                        <option value="ES">Espírito Santo</option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">Mato Grosso do Sul</option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraíba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">Rio de Janeiro</option>
                                        <option value="RN">Rio Grande do Norte</option>
                                        <option value="RS">Rio Grande do Sul</option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">Santa Catarina</option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantins</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to={`/lojas/${idCliente}`}>Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarLoja;