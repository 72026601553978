import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import api from '../../services/api';
import { FormatarData, TipoLacamento } from '../../utils/Utils';
import Logo from '../../assets/logo.png';
import { LandscapeOrientation } from '../../utils/landscapeOrientation';

function LancamentosPorCC() {
    let { centroCustoId, tipoData, dataInicial, dataFinal } = useParams();
    const [relatorio, setRelatorio] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let url = `/RelatoriosFinanceiros/lancamentosPorCentroCustoData/${centroCustoId}/${tipoData}/${dataInicial}/${dataFinal}`;
            await api.get(url).then(response => {
                setRelatorio(response ? response.data : []);
                console.log(response.data);
            });
        }
        fetchData();
    }, [centroCustoId, tipoData, dataInicial, dataFinal]);

    return (
        <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
            <LandscapeOrientation />
            <div style={{ fontSize: 12 }} className="row pr-3 pl-3 pt-3">
                <div style={{ width: 150 }}>
                    <img className='logo' src={Logo} alt="Logo" />
                </div>
                <div className="pl-2">
                    <b>Lançamentos por Centro de Custo</b><br />
                    <b>Centro de Custo: </b> {relatorio && relatorio.map((item) => { return item.CentroCusto })[0]}<br />
                    <b>{tipoData == 1 ? "Vencimento" : "Baixa"}</b>: {<FormatarData data={dataInicial} />} a {<FormatarData data={dataFinal} />}
                </div>
            </div>

            <div className="relatorio table-responsive mt-2">
                <table style={{ width: '100%' }} cellPadding="3px">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }} scope="col">Tipo</th>
                            <th scope="col">Natureza Financeira</th>
                            <th scope="col">Valor</th>
                            <th scope="col">Valor Baixado</th>
                            <th scope="col">Valor em Aberto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatorio && relatorio.map((item, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: "center" }}><TipoLacamento data={item.Tipo} /></td>
                                <td>{item.NaturezaFinanceira}</td>
                                <td style={{ textAlign: "right" }}>{item.Valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{item.ValorBaixado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{item.EmAberto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                        ))}
                        <tr style={{ fontWeight: "bold" }}>
                            <td style={{ textAlign: "center" }}>{relatorio ? relatorio.length : 0}</td>
                            <td>Total:</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.Valor, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorBaixado, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.EmAberto, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default LancamentosPorCC;