import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import api from '../../services/api';
import { TipoData, FormatarData } from '../../utils/Utils';
import Logo from '../../assets/logo.png';
import { LandscapeOrientation } from '../../utils/landscapeOrientation';
import MyChart from '../../components/Graficos/GraficoEmBarraHorizontal';

function AnaliseReceitaPorCompradores() {
    let { clienteId, tipoData, dataInicial, dataFinal, ordenarPor } = useParams();
    const [relatorio, setRelatorio] = useState([]);
    const [graficoQuantidadeDePedidos, setGraficoQuantidadeDePedidos] = useState();
    const [graficoTotalPecas, setGraficoTotalPecas] = useState();
    const [graficoCustoLiquido, setGraficoCustoLiquido] = useState();
    const [graficoValorComissaoTotal, setGraficoValorComissaoTotal] = useState();
    useEffect(() => {
        async function fetchData() {
            await api.get(`/RelatorioReceita/analiseReceitaPorCompradores/${clienteId}/${tipoData}/${dataInicial}/${dataFinal}/${ordenarPor}`).then(response => {
                setRelatorio(response ? response.data : null);

                if (response.data) {
                    const dataComprador = response.data.map(function (e) {
                        return e.Comprador;
                    });
                    const dataQuantidadeDePedidos = response.data.map(function (e) {
                        return e.QuantidadeDePedidos;
                    });
                    const dataTotalPecas = response.data.map(function (e) {
                        return e.TotalPecas;
                    });
                    const dataCustoLiquido = response.data.map(function (e) {
                        return e.CustoLiquido;
                    });
                    const dataValorComissaoTotal = response.data.map(function (e) {
                        return e.ValorComissaoTotal;
                    });

                    setGraficoQuantidadeDePedidos(getDataGrafico({ tipo: 'Quantidade de Pedidos', labels: dataComprador, valores: dataQuantidadeDePedidos }));
                    setGraficoTotalPecas(getDataGrafico({ tipo: 'Total Peças', labels: dataComprador, valores: dataTotalPecas }));
                    setGraficoCustoLiquido(getDataGrafico({ tipo: 'Custo Líquido', labels: dataComprador, valores: dataCustoLiquido }));
                    setGraficoValorComissaoTotal(getDataGrafico({ tipo: 'Valor Comissão', labels: dataComprador, valores: dataValorComissaoTotal }));
                }
            });
        }
        fetchData();
    }, [clienteId, tipoData, dataInicial, dataFinal, ordenarPor]);

    const getDataGrafico = (data) => {
        return {
            labels: data.labels,
            datasets: [
                {
                    label: data.tipo,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                    data: data.valores
                }
            ]
        };
    }
    return (
        <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
            <LandscapeOrientation />
            <div style={{ fontSize: 12 }} className="row pr-3 pl-3 pt-3">
                <div style={{ width: 150 }}>
                    <img className='logo' src={Logo} alt="Logo" />
                </div>
                <div className="pl-2">
                    <b>Relatório - Análise de Receitas por Compradores</b><br />
                    <b>{<TipoData tipo={tipoData} />}</b><br />
                    <b>Período:</b> {<FormatarData data={dataInicial} />} a {<FormatarData data={dataFinal} />}
                </div>
            </div>
            <h4>{clienteId !== '00000000-0000-0000-0000-000000000000' && relatorio[0] && relatorio[0].Cliente}</h4>
            <div className="relatorio table-responsive mt-2">
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th scope="col" rowspan="2">Comprador</th>
                            {clienteId === '00000000-0000-0000-0000-000000000000' && (
                                <th scope="col" rowspan="2">Cliente</th>
                            )}
                            <th scope="col" rowspan="2" style={{ textAlign: "right" }}>Quantidade de Pedidos</th>
                            <th scope="col" rowspan="2" style={{ textAlign: "right" }}>Total de Peças</th>
                            <th scope="col" rowspan="2" style={{ textAlign: "right" }}>Custo Liquido</th>
                            <th scope="col" colspan="4" style={{ textAlign: "center" }}>Valor Comissão</th>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "right" }}>Base de Cálculo</th>                            
                            <th style={{ textAlign: "right" }}>Parceiro</th>
                            <th style={{ textAlign: "right" }}>JR</th>
                            <th style={{ textAlign: "right" }}>Total</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {relatorio && relatorio.map((linha, index) => (
                            <tr key={index} style={{ fontSize: 12 }}>
                                <td>{linha.Comprador}</td>
                                {clienteId === '00000000-0000-0000-0000-000000000000' && (
                                    <td>{linha.Cliente}</td>
                                )}
                                <td style={{ textAlign: "right" }}>{linha.QuantidadeDePedidos}</td>
                                <td style={{ textAlign: "right" }}>{linha.TotalPecas.toLocaleString('pt-br', { maximumFractionDigits: 0 })}</td>
                                <td style={{ textAlign: "right" }}>{linha.CustoLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.BaseDeCalculo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoParceiro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoJosielRepresentacoes.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                        ))}
                        <tr style={{ fontWeight: "bold" }}>
                            {clienteId === '00000000-0000-0000-0000-000000000000' && (
                                <td></td>
                            )}
                            <td>Total:</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.QuantidadeDePedidos, 0)}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.TotalPecas, 0).toLocaleString('pt-br', { maximumFractionDigits: 0 })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.CustoLiquido, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.BaseDeCalculo, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoParceiro, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoJosielRepresentacoes, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoTotal, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className="row">
                <div className="col-md text-center">
                    <h5>Quantidade de Pedidos</h5>
                    {graficoQuantidadeDePedidos &&
                        <MyChart
                            data={graficoQuantidadeDePedidos}
                            width={100}
                            height={60}
                            formatMoeda={false} />
                    }
                </div>
                <div className="col-md text-center">
                    <h4>Total Peças</h4>
                    {graficoTotalPecas &&
                        <MyChart
                            data={graficoTotalPecas}
                            width={100}
                            height={60}
                            formatMoeda={false} />
                    }
                </div>
                <div className="col-md text-center">
                    <h4>Custo Líquido</h4>
                    {graficoCustoLiquido &&
                        <MyChart
                            data={graficoCustoLiquido}
                            width={100}
                            height={60}
                            formatMoeda={true} />
                    }
                </div>
                <div className="col-md text-center">
                    <h4>Valor Comissão</h4>
                    {graficoValorComissaoTotal &&
                        <MyChart
                            data={graficoValorComissaoTotal}
                            width={100}
                            height={60}
                            formatMoeda={true} />
                    }
                </div>
            </div>
        </div>
    );
}

export default AnaliseReceitaPorCompradores;