import React, { Component } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faTshirt, faAddressBook} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NovoFornecedor from "./novoFornecedor";
import EditarFornecedor from "./editarFornecedor";
import { mask } from 'remask';
import Erros from '../../components/Erro'

class Fornecedor extends Component {
    state = {
        localizarNome: "",
        fornecedores: []
    }

    async componentDidMount() {
        if (this.props.match.params.id) {
            await api.get(`/Fornecedor/id/${this.props.match.params.id}`).then(response => {
                this.setState({ fornecedores: [ response ? response.data : null ]});
            });
        } else {
            await api.get('/Fornecedor/todos').then(response => {
                this.setState({ fornecedores: response ? response.data : null });
            });
        }
    }

    handleInputLocalizarNome = e => {
        this.setState({ localizarNome: e.currentTarget.value });
    }

    handleLocalizar = async e => {
        e.preventDefault();
        await api.get('/Fornecedor/razaoSocial/' + encodeURIComponent(this.state.localizarNome === '' ? '%' : this.state.localizarNome)).then(response => {
            this.setState({ fornecedores: response ? response.data : null });
            this.props.history.push("/fornecedores");
        });
    }

    handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete('/Fornecedor?id=' + id).then(response => {
                if (response) {
                    this.setState({ fornecedores: this.state.fornecedores.filter(f => f.id !== id) });
                }
            });
        }
    }

    render() {
        const { localizarNome, fornecedores } = this.state;
        return (
            <div className="container" style={{ marginTop: 20 }}>
                <div className="card">
                    <div className="card-header">Fornecedores</div>
                    <Erros />
                    <div className="card-body">
                        <h5 className="card-title">Localizar</h5>
                        <div>
                            <form onSubmit={this.handleLocalizar}>
                                <div className="form-row">
                                    <div className="col-md-4 pt-2">
                                        <label className="sr-only">Razão Social</label>
                                        <input type="text" onChange={this.handleInputLocalizarNome} value={localizarNome} className="form-control" id="inputLocalizar" placeholder="Razão Social" />
                                    </div>
                                    <div className="col-md-1 pt-2">
                                        <div style={{ display: "inline-flex" }}>
                                            <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                            <Link className="btn btn-success mx-sm-4 ml-2 mb-2" to="/fornecedores/novo">Novo</Link>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">CNPJ/CPF</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fornecedores && fornecedores.map(fornecedor => (
                                            <tr key={fornecedor.id}>
                                                <td>{fornecedor.razaoSocial}</td>
                                                <td style={{width: "170px"}}>{mask(fornecedor.cnpj, fornecedor.cnpj.length === 11 ? ['999.999.999-99'] : ['99.999.999/9999-99'])}</td>
                                                <td style={{ textAlign: "right" }}>
                                                    <div style={{ display: "inline-flex" }}>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={'/fornecedores/editar/' + fornecedor.id}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={'/produtos/' + fornecedor.id}><FontAwesomeIcon className="text-success ml-2" icon={faTshirt} /></Link>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={'/contatos/' + fornecedor.id}><FontAwesomeIcon className="text-info ml-2" icon={faAddressBook} /></Link>
                                                        <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => this.handleExcluir(fornecedor.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Fornecedor, NovoFornecedor, EditarFornecedor };