import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

const options = (formatMoeda) => {
  return {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let valor = formatMoeda ? tooltipItem.xLabel.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : tooltipItem.xLabel;
          return valor;
        }
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
  }
}

export default function GraficoEmBarraHorizontal(props) {
  return (
    <div>
      <HorizontalBar
        data={props.data}
        width={props.width}
        height={props.height}
        options={options(props.formatMoeda)}
      />
    </div>
  );
};