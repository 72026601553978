import React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
  scales: {
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
      },
    ],
  },
}

export default function GraficoEmLinha(props) {
  return (
    <div>
      <Line
        data={props.data}
        width={props.width}
        height={props.height}
        options={options}
      />
    </div>
  );
};