import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../../components/Erro';

function EditarProduto() {
    let history = useHistory();
    let { id, idFornecedor } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [produto, setProduto] = useState([]);
    const [fornecedor, setFornecedor] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [subgrupos, setSubgrupos] = useState([]);

    useEffect(() => {
        async function fetchData() {            
            await api.get('/Fornecedor/id/' + idFornecedor).then(response => {
                setFornecedor(response ? response.data : null);
            });
            await api.get('/Grupo/todos').then(response => {
                setGrupos(response ? response.data : null);
            });
            await api.get('/SubGrupo/todos').then(response => {
                setSubgrupos(response ? response.data : null);
            });
            await api.get('/Produto/id/' + id).then(response => {
                setProduto(response ? response.data : null);
            });
        }
        fetchData();
    }, [id, idFornecedor]);

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setProduto(prev => ({ ...prev, [name]: value }));
    }

    const onSubmit = async data => {
        window.scrollTo(0, 0);
        await api.put('/Produto', data).then(response => {
            if (response) {
                history.push(`/produtos/${idFornecedor}`)
            }
        });
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">
                    <h5>{fornecedor ? fornecedor.razaoSocial : ""}</h5>
                    Produtos
                </div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Editar Produto</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" defaultValue={id} name="id" ref={register} />
                            <input type="hidden" defaultValue={idFornecedor} name="fornecedorId" ref={register} />

                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Descrição</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="descricao" id="Descrição" defaultValue={produto ? produto.descricao : ""} ref={register({ required: true })} placeholder="Descrição" />
                                    {errors.descricao && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Referência</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="referencia" id="referencia" defaultValue={produto ? produto.referencia : ""} ref={register({ required: true })} placeholder="Regferência" />
                                    {errors.referencia && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Sexo</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="sexo" id="sexo" value={produto ? produto.sexo : ""} onChange={handleInputChange} ref={register} placeholder="Sexo">
                                        <option value=""></option>
                                        <option value="M">Masculino</option>
                                        <option value="F">Feminino</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Grupo</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="grupoId" id="grupoId" value={produto ? produto.grupoId : ""} onChange={handleInputChange} ref={register({ required: true })} placeholder="Grupo">
                                        <option value=""></option>
                                        {
                                            grupos && grupos.map((grupo, index) => (
                                                <option key={index} value={grupo.id}>{grupo.nome}</option>
                                            ))
                                        }
                                    </select>
                                    {errors.grupoId && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Subgrupo</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="subGrupoId" id="subGrupoId" value={produto ? produto.subGrupoId : ""} onChange={handleInputChange} ref={register({ required: true })} placeholder="Subgrupo">
                                        <option value=""></option>
                                        {
                                            subgrupos && subgrupos.map((grupo, index) => (
                                                <option key={index} value={grupo.id}>{grupo.nome}</option>
                                            ))
                                        }
                                    </select>
                                    {errors.subgrupoId && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>                                                     
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to={`/produtos/${idFornecedor}`}>Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarProduto;