import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import api from '../../services/api';
import { TipoData, FormatarData } from '../../utils/Utils';
import Logo from '../../assets/logo.png';
import { LandscapeOrientation } from '../../utils/landscapeOrientation';


function ReceitaPorTipoData() {
    let { tipoData, dataInicial, dataFinal, ordenarPor } = useParams();
    const [relatorio, setRelatorio] = useState([]);
    useEffect(() => {
        async function fetchData() {
            await api.get(`/RelatorioReceita/receitaportipodata/${tipoData}/${dataInicial}/${dataFinal}/${ordenarPor}`).then(response => {
                setRelatorio(response ? response.data : null);                
            });
        }
        fetchData();
    }, [tipoData, dataInicial, dataFinal, ordenarPor]);

    return (
        <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
            <LandscapeOrientation />
            <div style={{ fontSize: 12 }} className="row pr-3 pl-3 pt-3">
                <div style={{ width: 150 }}>
                    <img className='logo' src={Logo} alt="Logo" />
                </div>
                <div className="pl-2">
                    <b>Relatório de Receitas</b><br />
                    <b>{<TipoData tipo={tipoData} />}</b><br />
                    <b>Período:</b> {<FormatarData data={dataInicial} />} a {<FormatarData data={dataFinal} />}
                </div>
            </div>
            <div className="relatorio table-responsive mt-2">
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th scope="col" rowspan="2">Cliente</th>
                            <th scope="col" rowspan="2" style={{ textAlign: "right" }}>Quantidade de Pedidos</th>
                            <th scope="col" rowspan="2" style={{ textAlign: "right" }}>Total de Peças</th>
                            <th scope="col" rowspan="2" style={{ textAlign: "right" }}>Custo Liquido</th>
                            <th scope="col" colspan="4" style={{ textAlign: "center" }}>Valor Comissão</th>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "right" }}>Base De Cálculo</th>                            
                            <th style={{ textAlign: "right" }}>Parceiro</th>
                            <th style={{ textAlign: "right" }}>JR</th>
                            <th style={{ textAlign: "right" }}>Total</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {relatorio && relatorio.map((linha, index) => (
                            <tr key={index} style={{ fontSize: 12 }}>
                                <td>{linha.Cliente}</td>
                                <td style={{ textAlign: "right" }}>{linha.QuantidadeDePedidos}</td>
                                <td style={{ textAlign: "right" }}>{linha.TotalPecas}</td>
                                <td style={{ textAlign: "right" }}>{linha.CustoLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.BaseDeCalculo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoParceiro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoJosielRepresentacoes.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                        ))}
                        <tr style={{ fontWeight: "bold" }}>
                            <td>Total:</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.QuantidadeDePedidos, 0)}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.TotalPecas, 0)}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.CustoLiquido, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.BaseDeCalculo, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoParceiro, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoJosielRepresentacoes, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoTotal, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReceitaPorTipoData;