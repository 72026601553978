import React, { Component } from 'react';
import Baixado from '../assets/baixado.png';
import BaixadoParcialmente from '../assets/baixadoParcialmente.png';
import EmAberto from '../assets/emAberto.png';
import EmAtraso from '../assets/emAtraso.png';
import Receita from '../assets/receita.png';
import Despesa from '../assets/despesa.png';

class TipoData extends Component {

    tipoData = (tipo) => {
        switch (tipo) {
            case '0':
                return 'Data Compra';
            case '1':
                return 'Data de Previsão de Faturamento'
            case '2':
                return 'Data de Faturamento';
            default:
                return '';
        }
    }
    render() {
        const { tipo } = this.props;
        return (
            this.tipoData(tipo)
        );
    }
}

class MeioPagamento extends Component {

    meioPagamento = (meio) => {
        switch (meio) {
            case 1:
                return 'Dinheiro'
            case 2:
                return 'Débito em Conta';
            case 3:
                return 'Cartão de Crédito';
            case 4:
                return 'Cartão de Débito';
            case 5:
                return 'Cheque';
            case 6:
                return 'Boleto';
            case 7:
                return 'Transferência';
            case 8:
                return 'PIX';
            default:
                return '';
        }
    }
    render() {
        const { meio } = this.props;
        return (
            this.meioPagamento(meio)
        );
    }
}

class FormatarData extends Component {

    FormataStringData = data => {
        if (data) {
            var ano = data.split("-")[0];
            var mes = data.split("-")[1];
            var dia = data.split("-")[2];
            return ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ano;
        }
        return '-';
    }
    render() {
        const { data } = this.props;
        return (
            this.FormataStringData(data)
        );
    }
}

function StatusLacamento(props) {
    const selectStatus = (data) => {
        /*EmAberto = 0, Baixado = 1, BaixadoParcialmente = 2, EmAtraso = 3 */
        switch (data) {
            case 0:
                return { Icon: EmAberto, Label: 'Em Aberto' };
            case 1:
                return { Icon: Baixado, Label: 'Baixado' };
            case 2:
                return { Icon: BaixadoParcialmente, Label: 'Baixado Parcialmente' };
            case 3:
                return { Icon: EmAtraso, Label: 'Em Atraso' };
            default:
                return '';
        }
    }

    if (props.data >= 0) {
        return (
            <img src={selectStatus(props.data).Icon} alt={selectStatus(props.data).Label} title={selectStatus(props.data).Label} width="24" height="24"></img>
        )
    } else {
        return (
            ""
        )
    }
}

function TipoLacamento(props) {
    const selectStatus = (data) => {
        /*Despesa = 1, Receita = 2 */
        switch (data) {
            case 1:
                return { Icon: Despesa, Label: 'Despesa' };
            case 2:
                return { Icon: Receita, Label: 'Receita' };
            default:
                return '';
        }
    }

    if (props.data) {
        return (
            <img src={selectStatus(props.data).Icon} alt={selectStatus(props.data).Label} title={selectStatus(props.data).Label} width="24" height="24"></img>
        )
    } else {
        return (
            ""
        )
    }
}
export { TipoData, FormatarData, StatusLacamento, TipoLacamento, MeioPagamento };