import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import { mask, unMask } from 'remask';
import Erros from '../../../components/Erro';

function EditarContato() {
    let history = useHistory();
    let { id, idFornecedor } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [contato, setContato] = useState([]);
    const [fornecedor, setFornecedor] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await api.get('/Fornecedor/id/' + idFornecedor).then(response => {
                setFornecedor(response ? response.data : null);
            });

            await api.get('/Contato/id/' + id).then(response => {
                setContato(response ? response.data : null);
            });
        }
        fetchData();
    }, [id, idFornecedor]);

    const onSubmit = async data => {
        window.scrollTo(0, 0);
        data.telefone = unMask(data.telefone);
        await api.put('/Contato', data).then(response => {
            if (response) {
                history.push(`/contatos/${idFornecedor}`)
            }
        });
    };

    const onChangeMask = (event, maskFormat) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setContato(prev => ({ ...prev, [name]: mask(unMask(value), maskFormat) }));
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">
                    <h5>{fornecedor ? fornecedor.razaoSocial : ""}</h5>
                    Contatos
                </div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Editar Contato</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" defaultValue={id} name="id" ref={register} />
                            <input type="hidden" defaultValue={idFornecedor} name="fornecedorId" ref={register} />

                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Nome</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="nome" id="Nome" defaultValue={contato ? contato.nome : ""} ref={register({ required: true })} placeholder="Nome" />
                                    {errors.nome && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Função</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="funcao" id="funcao" defaultValue={contato ? contato.funcao : ""} ref={register} placeholder="Função" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">E-Mail</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="email" className="form-control" name="email" id="email" defaultValue={contato ? contato.email : ""} ref={register} placeholder="E-Mail" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Telefone</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="telefone" id="telefone" value={contato ? contato.telefone : ""} onChange={(e) => onChangeMask(e, ['(99) 99999-9999'])} ref={register} placeholder="Telefone" />
                                 </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to={`/contatos/${idFornecedor}`}>Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarContato;