import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import Erros from '../../../components/Erro';
import NovoNaturezaFinanceira from './novoNaturezaFinanceria';

function NaturezasFinanceiras() {
    const [naturezasFinanceiras, setNaturezasFinanceiras] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await api.get('/NaturezaFinanceira/todos').then(response => {
                setNaturezasFinanceiras(response ? response.data : null);
            });
        }
        fetchData();
    }, [])

    const handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete(`/NaturezaFinanceira?id=${id}`).then(response => {
                if (response) {
                    setNaturezasFinanceiras(naturezasFinanceiras.filter(l => l.id !== id));
                }
            });
        }
    }

    return (
        <div>
            <div className="container" style={{ marginTop: 20 }}>
                <div className="card">
                    <div className="card-header">Naturezas Financeiras</div>
                    <Erros />
                    <div className="card-body">
                        <Link className="btn btn-success  mx-sm-1 ml-2 mb-2" to={`/naturezaFinanceira/novo`}>Nova</Link>
                        <div>
                            {naturezasFinanceiras && naturezasFinanceiras.map(nf => (
                                <div key={nf.id}>
                                    {nf.sintetico &&
                                        <b>{nf.descricao}</b>
                                    }
                                    {!nf.sintetico &&
                                        <>
                                            {nf.descricao}
                                            <Link className="mx-sm-1 ml-2 mb-2" to={`/naturezaFinanceira/${nf.id}`}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                            <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => handleExcluir(nf.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                        </>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { NaturezasFinanceiras, NovoNaturezaFinanceira };