import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../Erro'

function EditarCadastro({ controller, listName, title }) {
    let history = useHistory();
    let { id } = useParams();
    const [item, setItem] = useState([]);
    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        async function getItem() {
            await api.get(`/${controller}/id/${id}`).then(response => {
                setItem(response ? response.data : null);
            });
        }
        getItem();
    },[id,controller]);

    const onSubmit = async data => {
        window.scrollTo(0, 0);
        await api.put(`/${controller}`, data).then(response => {
            if (response) {
                history.push(`/cadastro/${listName}`)
            }
        });
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">{title}</div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Editar {controller}</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" defaultValue={id} name="id" ref={register} />
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Nome</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="nome" id="nome" defaultValue={item ? item.nome : ""} ref={register({ required: true })} placeholder="Nome" />
                                    {errors.razaoSocial && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to={`/cadastro/${listName}`}>Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarCadastro;