import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import api from '../../services/api';
import { FormatarData } from '../../utils/Utils';
import Logo from '../../assets/logo.png';
import { LandscapeOrientation } from '../../utils/landscapeOrientation';

function LancamentosEmAberto() {
    let { clienteFornecedorId } = useParams();
    const [relatorio, setRelatorio] = useState([]);
    const [statusPedidos, setStatusPedidos] = useState();
    useEffect(() => {
        async function fetchData() {
            let url = `/RelatorioPedidos/lacamentosporclientefornecedor/${clienteFornecedorId}`;
            await api.get(url).then(response => {
                setRelatorio(response ? response.data : null);
            });
            await api.get('/Pedido/listaStatus').then(response => {
                setStatusPedidos(response ? response.data : null);
            });
        }
        fetchData();
    }, [clienteFornecedorId]);

    /*function percentualComissao() {
        const comissao = relatorio.reduce((a, v) => a = a + v.ValorComissao, 0)
        const total = relatorio.reduce((a, v) => a = a + v.CustoLiquido, 0)
        return (comissao / total) * 100
    }*/

    return (
        <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
            <LandscapeOrientation />
            <div style={{ fontSize: 12 }} className="row pr-3 pl-3 pt-3">
                <div style={{ width: 150 }}>
                    <img className='logo' src={Logo} alt="Logo" />
                </div>
                <div className="pl-2">
                    <b>Lançamentos por Cliente/Fornecedor</b><br />
                    <b>Fornecedor: </b> {relatorio && relatorio.map((item) => { return item.Fornecedor })[0]}<br />
                    <b>Status: </b> {relatorio && relatorio.map((item) => { return statusPedidos ? statusPedidos.find(s => s.value === item.StatusPedido).label : '-' })[0]}
                </div>
            </div>
            <div className="relatorio table-responsive mt-2">
                <table style={{ width: '100%' }} cellPadding="3px">
                    <thead>
                        <tr>
                            <th scope="col" rowSpan="2">Nº Ped</th>
                            <th scope="col" rowSpan="2">Cliente</th>
                            <th scope="col" rowSpan="2">Comprador</th>
                            <th scope="col" rowSpan="2">Dt Compra</th>
                            <th scope="col" rowSpan="2">Dt Prev Faturamento</th>
                            <th scope="col" rowSpan="2">Dt Faturamento</th>
                            <th scope="col" rowSpan="2" style={{ textAlign: "right" }}>Total de Peças</th>
                            <th scope="col" rowSpan="2" style={{ textAlign: "right" }}>Custo Total</th>
                            <th scope="col" style={{ textAlign: "center" }}>Valor Comissão</th>
                            <th scope="col" style={{ textAlign: "center" }}>% Comissão</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatorio && relatorio.map((linha, index) => (
                            <tr key={index} style={{ fontSize: 12 }}>
                                <td>{linha.Numero}</td>
                                <td>{linha.Cliente}</td>
                                <td>{linha.Comprador}</td>
                                <td>{<FormatarData data={linha.DataCompra} />}</td>
                                <td>{<FormatarData data={linha.DataPrevisaoFaturamento} />}</td>
                                <td>{<FormatarData data={linha.DataFaturamento} />}</td>
                                <td style={{ textAlign: "right" }}>{linha.TotalPecas.toLocaleString('pt-br', { maximumFractionDigits: 0 })}</td>
                                <td style={{ textAlign: "right" }}>{linha.CustoLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{((linha.ValorComissao / linha.CustoLiquido) * 100).toLocaleString('pt-br', { maximumFractionDigits: 2 })}%</td>
                            </tr>
                        ))}
                        <tr style={{ fontWeight: "bold" }}>
                            <td>{relatorio ? relatorio.length : 0}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total:</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.TotalPecas, 0).toLocaleString('pt-br', { maximumFractionDigits: 0 })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.CustoLiquido, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissao, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
        </div>
    );
}

export default LancamentosEmAberto;