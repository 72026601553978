import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import jwt_decode from 'jwt-decode';

class Hender extends Component {
    state = {
        administrador: false,
        cadFinanceiro: false
    }

    async componentDidMount() {
        var token = localStorage.getItem("token");
        if (token !== null) {
            var decoded = jwt_decode(token);
            this.setState({ administrador: decoded.administrador === 'True' });
            this.setState({ cadFinanceiro: decoded.cadFinanceiro === 'M' || decoded.cadFinanceiro === 'V' });
        }
    }

    handleLogout = e => {
        e.preventDefault();
        localStorage.removeItem('token');
        window.location.replace("/login");
    }

    render() {
        const { administrador, cadFinanceiro } = this.state;
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <a className="navbar-brand" href="/"><img className='logo' src={Logo} alt="Logo" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="/#">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Cadastros</a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                {administrador &&
                                    <>
                                        <Link className="dropdown-item" to="/usuarios">Usuários</Link>
                                        <div className="dropdown-divider"></div>
                                    </>
                                }

                                <Link className="dropdown-item" to="/fornecedores">Fornecedores</Link>
                                <Link className="dropdown-item" to="/clientes">Clientes</Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to="/cadastro/grupos">Grupos</Link>
                                <Link className="dropdown-item" to="/cadastro/subgrupos">Subgrupos</Link>
                                <Link className="dropdown-item" to="/cadastro/materiais">Materiais</Link>
                                <Link className="dropdown-item" to="/parceiros">Parceiros</Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to="/clienteFornecedor">Cliente/Fornecedor</Link>
                                <Link className="dropdown-item" to="/naturezaFinanceira">Natureza Financeira</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/pedidos">Pedidos</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/relatorios">Relatórios</Link>
                        </li>
                        {(administrador || cadFinanceiro) &&
                            <>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Financeiro</a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

                                        <Link className="dropdown-item" to="/financeiro/lancamentos">Lançamentos</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="/financeiro/relatorios">Relatórios</Link>                                        
                                    </div>
                                </li>
                            </>
                        }
                    </ul>
                    <button onClick={this.handleLogout} className="btn my-2 my-sm-0" type="submit">Sair</button>

                </div>
            </nav>
        );
    }
}

export default Hender;