import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { permissoes } from '../../services/costantes';
import api from '../../services/api';
import { useHistory } from "react-router-dom";
import Erros from '../../components/Erro';

function NovoUsuario() {
    let history = useHistory();
    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = async data => {
        window.scrollTo(0, 0);
        await api.post('/Usuario', data).then(response => {
            if (response) {
                history.push("/usuarios")
            }
        });
    };
    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">Usuários</div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Novo Usuário</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Nome</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="nome" id="Nome" ref={register({ required: true })} placeholder="Nome" />
                                    {errors.nome && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">E-Mail</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="email" className="form-control" name="email" id="Email" ref={register({ required: true })} placeholder="E-Mail" />
                                    {errors.email && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <fieldset className="form-group">
                                <div className="row">
                                    <legend className="col-form-label col-sm-2 pt-0">Senha</legend>
                                    <div className="col-sm-10">
                                        <div className="">
                                            <div style={{ display: "inline-flex" }}>
                                                <input type="password" className="form-control" name="senha" id="Senha" ref={register({ required: true })} placeholder="Senha" />
                                                {errors.senha && <span className="text-danger ml-2">*</span>}
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div style={{ display: "inline-flex" }}>
                                                <input type="password" className="form-control" name="confirmarSenha" id="confirmarSenha" ref={register({
                                                    validate: (value) => {
                                                        return value === watch('senha');
                                                    }
                                                })} placeholder="Confirmar Senha" />
                                                {errors.confirmarSenha && <span className="text-danger ml-2">Senha diferente!</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <h5>Permissões</h5>
                            <div className="form-group row">
                                <div className="col-sm-2">Administrador</div>
                                <div className="col-sm-10">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="administrador" defaultChecked={false} ref={register} id="Administrador" />
                                        <label className="form-check-label">Ativo</label>
                                    </div>
                                </div>
                            </div>
                            {permissoes.map((p, index) => (
                                <fieldset key={index} className="form-group">
                                    <div className="row">
                                        <legend className="col-form-label col-sm-2 pt-0">{p.label}</legend>
                                        <div className="col-sm-10">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name={p.name} ref={register} id={p.name + '1'} value="D" defaultChecked="true" />
                                                <label className="form-check-label">Desativado</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name={p.name} ref={register} id={p.name + '2'} value="V" />
                                                <label className="form-check-label">Visualizar</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name={p.name} ref={register} id={p.name + '3'} value="M" />
                                                <label className="form-check-label">Inserir/Modificar</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            ))}
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to="/usuarios">Cancelar</Link>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NovoUsuario;