import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import api from '../../services/api';
import { TipoData, FormatarData } from '../../utils/Utils';
import Logo from '../../assets/logo.png';
import { LandscapeOrientation } from '../../utils/landscapeOrientation';

function ListaPedidos() {
    let { tipoData, dataInicial, dataFinal, tipoRelatorio, guid, usarValoresFaturados, status } = useParams();
    const [relatorio, setRelatorio] = useState([]);
    const [statusPedidos, setStatusPedidos] = useState();
    useEffect(() => {
        async function fetchData() {
            let url = `/RelatorioPedidos/listapedidosporperiodo/${tipoData}/${dataInicial}/${dataFinal}/${usarValoresFaturados}/${status}`;
            if (tipoRelatorio) {
                url = `/RelatorioPedidos/${tipoRelatorio}/${guid}/${tipoData}/${dataInicial}/${dataFinal}/${usarValoresFaturados}/${status}`;
            }
            await api.get(url).then(response => {
                setRelatorio(response ? response.data : null);
            });
            await api.get('/Pedido/listaStatus').then(response => {
                setStatusPedidos(response ? response.data : null);
            });
        }
        fetchData();
    }, [tipoData, dataInicial, dataFinal, tipoRelatorio, guid, usarValoresFaturados, status]);

    return (
        <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
            <LandscapeOrientation />
            <div style={{ fontSize: 12 }} className="row pr-3 pl-3 pt-3">
                <div style={{ width: 150 }}>
                    <img className='logo' src={Logo} alt="Logo" />
                </div>
                <div className="pl-2">
                    <b>Lista de Pedidos</b><br />
                    <b>{<TipoData tipo={tipoData} />}</b><br />
                    <b>Período:</b> {<FormatarData data={dataInicial} />} a {<FormatarData data={dataFinal} />}
                </div>
            </div>
            <div className="relatorio table-responsive mt-2">
                <table style={{ width: '100%' }} cellPadding="3px">
                    <thead>
                        <tr>
                            <th scope="col" rowSpan="2">Nº Ped</th>
                            <th scope="col" rowSpan="2">Nº Ped Cliente</th>
                            <th scope="col" rowSpan="2">Status</th>
                            <th scope="col" rowSpan="2">Cliente</th>
                            <th scope="col" rowSpan="2">Fornecedor</th>
                            <th scope="col" rowSpan="2">Comprador</th>
                            <th scope="col" rowSpan="2">Dt Compra</th>
                            <th scope="col" rowSpan="2">Dt Prev Faturamento</th>
                            <th scope="col" rowSpan="2">Dt Faturamento</th>
                            <th scope="col" rowSpan="2" style={{ textAlign: "right" }}>Total de Peças</th>
                            <th scope="col" rowSpan="2" style={{ textAlign: "right" }}>Custo Liquido</th>
                            <th scope="col" colSpan="4" style={{ textAlign: "center" }}>Valor Comissão</th>
                        </tr>
                        <tr>
                            <th style={{ textAlign: "right" }}>Base De Cálculo</th>    
                            <th style={{ textAlign: "right" }}>Parceiro</th>
                            <th style={{ textAlign: "right" }}>JR</th>
                            <th style={{ textAlign: "right" }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>                        
                        {relatorio && relatorio.map((linha, index) => (
                            <tr key={index} style={{ fontSize: 12 }}>
                                <td>{linha.Numero}</td>
                                <td>{linha.NumeroPedidoCliente}</td>
                                <td>{statusPedidos ? statusPedidos.find(s => s.value === linha.StatusPedido).label : '-'}</td>
                                <td>{linha.Cliente}</td>
                                <td>{linha.Fornecedor}</td>
                                <td>{linha.Comprador}</td>
                                <td>{<FormatarData data={linha.DataCompra} />}</td>
                                <td>{<FormatarData data={linha.DataPrevisaoFaturamento} />}</td>
                                <td>{<FormatarData data={linha.DataFaturamento} />}</td>
                                <td style={{ textAlign: "right" }}>{linha.TotalPecas}</td>
                                <td style={{ textAlign: "right" }}>{linha.CustoLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.BaseDeCalculo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoParceiro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoJosielRepresentacoes.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: "right" }}>{linha.ValorComissaoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                        ))}
                        <tr style={{ fontWeight: "bold" }}>
                            <td>{relatorio ? relatorio.length : 0}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total:</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.TotalPecas, 0)}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.CustoLiquido, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.BaseDeCalculo, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoParceiro, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoJosielRepresentacoes, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                            <td style={{ textAlign: "right" }}>{relatorio.reduce((a, v) => a = a + v.ValorComissaoTotal, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        </tr>
                    </tbody>
                </table>               
            </div>
            <br/>
        </div>
    );
}

export default ListaPedidos;