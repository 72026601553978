import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../components/Erro'

function EditarParceiro() {
    let history = useHistory();
    let { id } = useParams();
    const [parceiro, setParceiro] = useState([]);

    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        async function getParceiro() {
                await api.get('/Parceiro/id/' + id).then(response => {
                    setParceiro(response ? response.data : null);
                });            
        }
        getParceiro();
    }, [id]);    

    const onSubmit = async data => {
        window.scrollTo(0, 0);        
        data.percentalPadrao = data.percentalPadrao === '' ? null : parseFloat(data.percentalPadrao.replace(',', '.'));
        await api.put('/Parceiro', data).then(response => {
            if (response) {
                history.push("/parceiros")
            }
        });
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">Parceiros</div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Editar Parceiro</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" defaultValue={id} name="id" ref={register} />
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Nome</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="nome" id="nome" defaultValue={parceiro ? parceiro.nome : ""} ref={register({ required: true })} placeholder="Nome" />
                                    {errors.nome && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>                            
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Percental Padrão</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="percentalPadrao" id="percentalPadrao" defaultValue={parceiro.percentalPadrao ? parceiro.percentalPadrao.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' }) : ""} ref={register({ required: true })} placeholder="Percental Padrão" />
                                    {errors.percentalPadrao && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>                            
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to="/parceiros">Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarParceiro;