import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import Erros from '../../../components/Erro';
import NovoClienteFornecedor from './novoClienteFornecedor';

function ClientesFornecedores() {
    const [clientesFornecedores, setClientesFornecedores] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await api.get('/ClienteFornecedor/todos').then(response => {
                setClientesFornecedores(response ? response.data : null);
            });
        }
        fetchData();
    }, [])

    const handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete(`/ClienteFornecedor?id=${id}`).then(response => {
                if (response) {
                    setClientesFornecedores(clientesFornecedores.filter(l => l.id !== id));
                }
            });
        }
    }

    return (
        <div>
            <div className="container" style={{ marginTop: 20 }}>
                <div className="card">
                    <div className="card-header">Clientes/Fornecedores</div>
                    <Erros />
                    <div className="card-body">
                        <Link className="btn btn-success  mx-sm-1 ml-2 mb-2" to={`/clienteFornecedor/Novo`}>Novo</Link>
                        <div>
                            <div className="table-responsive mt-3">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientesFornecedores && clientesFornecedores.map(cliFor => (
                                            <tr key={cliFor.id}>
                                                <td>{cliFor.nome}</td>
                                                <td style={{ textAlign: "right" }}>
                                                    <div style={{ display: "inline-flex" }}>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={`/clienteFornecedor/${cliFor.id}`}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                                        <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => handleExcluir(cliFor.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ClientesFornecedores, NovoClienteFornecedor };