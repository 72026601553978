import React, { Component } from 'react';
import moment from 'moment'
import api from '../../services/api';
import MyChart from '../../components/Graficos/GraficoEmLinha';

class Main extends Component {
    state = {
        graficoComprasFaturamento: null,
        totalPedidosEmAtraso: null,
        totalProximosAoFaturamento: null,
    }

    async componentDidMount() {
        await api.get(`/Graficos/UltimosMesesCompraFaturado/5`).then(response => {
            if (response) {
                var data = {
                    labels: response.data.labels,
                    datasets: [
                        {
                            label: 'Compra',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 2,
                            fill: false,
                            hoverBackgroundColor: '#a04a5d',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: response.data.quantidadeCompra
                        },
                        {
                            label: 'Faturamento',
                            borderColor: 'rgba(99,99,132,1)',
                            fill: false,
                            borderWidth: 2,
                            hoverBackgroundColor: '#a04a5d',
                            hoverBorderColor: 'rgba(99,99,132,1)',
                            data: response.data.quantidadeFaturado
                        }
                    ]
                };

                this.setState({ graficoComprasFaturamento: data });
            }
        });

        await api.get(`Pedido/totalPedidosEmAtraso`).then(response => {
            if (response) {
                this.setState({ totalPedidosEmAtraso: response.data });
            }
        });

        await api.get(`/Pedido/totalProximosAoFaturamento/15`).then(response => {
            if (response) {
                this.setState({ totalProximosAoFaturamento: response.data });
            }
        });
    }

    onOpenRelatorioProximosFaturamento = async () => {
        let currentDate = new Date();
        let currentDatePlus = new Date();
        currentDatePlus.setDate(currentDatePlus.getDate() + 15);
        window.open(`/relatorio/listaPedidos/1/${moment(currentDate).format('YYYY-MM-DD')}/${moment(currentDatePlus).format('YYYY-MM-DD')}/false/2`);
    }

    onOpenRelatorioEmAtraso = async () => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        window.open(`/relatorio/listaPedidos/1/0001-01-01/${moment(currentDate).format('YYYY-MM-DD')}/false/2`);
    }

    render() {
        const { graficoComprasFaturamento, totalPedidosEmAtraso, totalProximosAoFaturamento } = this.state;
        return (
            <div className="container" style={{ marginTop: 20 }}>
                <div className="row">
                    <div className="col-md-6">
                        <h4>Compras X Faturamento</h4>
                        {graficoComprasFaturamento &&
                            <MyChart
                                data={graficoComprasFaturamento}
                                width={100}
                                height={60} />
                        }
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Pedidos em Atraso</h5>
                                <p className="card-text"><span className="badge badge-pill badge-danger">{totalPedidosEmAtraso}</span> pedido(s) em produção que ultrapassou a previsão de faturamento.</p>
                                <a href="#" onClick={this.onOpenRelatorioEmAtraso} className="btn btn-primary">Listar Pedidos</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Faturamento</h5>
                                <p className="card-text"><span className="badge badge-pill badge-danger">{totalProximosAoFaturamento}</span> pedido(s) com previsão de faturamento nos próximos 15 dias.</p>
                                <a href="#" onClick={this.onOpenRelatorioProximosFaturamento} className="btn btn-primary">Listar Pedidos</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Main;