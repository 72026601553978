import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import api from '../../services/api';
import { FormatarData, MeioPagamento, StatusLacamento, TipoData, TipoLacamento } from '../../utils/Utils';
import Logo from '../../assets/logo.png';
import { LandscapeOrientation } from '../../utils/landscapeOrientation';

function LancamentosPorNF() {
    let { naturezaFinanceiraId, tipoData, dataInicial, dataFinal, clienteFornecedorId } = useParams();
    const [relatorio, setRelatorio] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let url = `/RelatoriosFinanceiros/lancamentosPorNaturezaFinanceira/${naturezaFinanceiraId}/${tipoData}/${dataInicial}/${dataFinal}/${clienteFornecedorId}`;
            await api.get(url).then(response => {
                setRelatorio(response ? response.data : []);
                console.log(response.data);
            });
        }
        fetchData();
    }, [naturezaFinanceiraId, tipoData, dataInicial, dataFinal, clienteFornecedorId]);

    return (
        <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
            <LandscapeOrientation />
            <div style={{ fontSize: 12 }} className="row pr-3 pl-3 pt-3">
                <div style={{ width: 150 }}>
                    <img className='logo' src={Logo} alt="Logo" />
                </div>
                <div className="pl-2">
                    <b>Lançamentos por Natureza Financeira</b><br />
                    <b>Natureza Financeira: </b> {relatorio && relatorio.map((item) => { return item.naturezaFinanceira })[0]}<br />
                    {clienteFornecedorId !== '00000000-0000-0000-0000-000000000000' && <b>Cliente/Fornecedor: </b>}
                    {clienteFornecedorId !== '00000000-0000-0000-0000-000000000000' && relatorio && relatorio.map((item) => { return item.itens[0].clienteFornecedor })[0]}
                    {clienteFornecedorId !== '00000000-0000-0000-0000-000000000000' && <br />}
                    <b>{tipoData == 1 ? "Vencimento" : "Baixa"}</b>: {<FormatarData data={dataInicial} />} a {<FormatarData data={dataFinal} />}
                </div>
            </div>

            <div className="relatorio table-responsive mt-2">
                <table style={{ width: '100%' }} cellPadding="3px">
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center" }} scope="col">Tipo</th>
                            <th style={{ textAlign: "center" }} scope="col">Status</th>
                            {clienteFornecedorId === '00000000-0000-0000-0000-000000000000' && <th scope="col">Cliente/Fornecedor</th>}
                            <th scope="col">Centro de Custo</th>
                            <th scope="col">Data do Vencimento</th>
                            <th scope="col">Valor</th>
                            <th scope="col">Data da Baixa</th>
                            <th scope="col">Valor Baixado</th>
                            <th scope="col">Valor em Aberto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {relatorio && relatorio.map((linha, index) => (
                            <>
                                <tr key={index} style={{ fontSize: 14, backgroundColor: "#E8ECF0" }} className="thead-light">
                                    <td colSpan={9}><b>{<MeioPagamento meio={linha.meioPagamento} />}</b></td>
                                </tr>
                                {linha.itens.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: "center" }}><TipoLacamento data={item.tipo} /></td>
                                        <td style={{ textAlign: "center" }}><StatusLacamento data={item.status} /> </td>
                                        {clienteFornecedorId === '00000000-0000-0000-0000-000000000000' && <td>{item.clienteFornecedor}</td>}
                                        <td>{item.centroCusto}</td>
                                        <td>{<FormatarData data={item.dataVencimento} />}</td>
                                        <td style={{ textAlign: "right" }}>{item.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>{<FormatarData data={item.dataBaixa} />}</td>
                                        <td style={{ textAlign: "right" }}>{item.valorBaixado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                        <td style={{ textAlign: "right" }}>{item.valorEmAberto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                ))}
                                <tr style={{ fontWeight: "bold" }}>
                                    <td style={{ textAlign: "center" }}>{linha ? linha.itens.length : 0}</td>
                                    <td></td>
                                    <td></td>
                                    {clienteFornecedorId === '00000000-0000-0000-0000-000000000000' && <td></td>}
                                    <td>Total:</td>
                                    <td style={{ textAlign: "right" }}>{linha.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    <td></td>
                                    <td style={{ textAlign: "right" }}>{linha.valorTotalBaixado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    <td style={{ textAlign: "right" }}>{linha.valorTotalEmAberto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default LancamentosPorNF;