import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import api from '../../services/api';
import Select from 'react-select'

function Menu() {

    const options = [
        { value: '1', label: 'Cadastrado' },
        { value: '2', label: 'Em Produção' },
        { value: '3', label: 'Entregue' },
        { value: '4', label: 'Cobrar Comissão' },
        { value: '5', label: 'Finalizado' }
    ]

    const { register, handleSubmit, errors } = useForm();
    const { register: register1, handleSubmit: handleSubmit1, errors: errors1 } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, errors: errors2 } = useForm();
    const { register: register3, handleSubmit: handleSubmit3, errors: errors3 } = useForm();
    const [status3, setStatus3] = useState(options.map(({ value }) => value));
    const { register: register4, handleSubmit: handleSubmit4, errors: errors4 } = useForm();
    const [status4, setStatus4] = useState(options.map(({ value }) => value));
    const { register: register5, handleSubmit: handleSubmit5, errors: errors5 } = useForm();
    const [status5, setStatus5] = useState(options.map(({ value }) => value));
    const { register: register6, handleSubmit: handleSubmit6, errors: errors6 } = useForm();
    const [status6, setStatus6] = useState(options.map(({ value }) => value));
    const [relatorios, setRelatorios] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [compradores, setCompradores] = useState([]);

    useEffect(() => {
        async function getParametros() {
            await api.get('/Relatorio/listarRelatorios').then(response => {
                setRelatorios(response ? response.data : null);
            });

            await api.get(`/Cliente/todos`).then(response => {
                setClientes(response ? response.data : null);
            });

            await api.get(`/Fornecedor/todos`).then(response => {
                setFornecedores(response ? response.data : null);
            });
        }
        getParametros();
    }, []);

    const onSubmitReceitaPorTipoData = async (data) => {
        window.open(`/relatorio/receitaPorTipoData/${data.tipoData}/${data.dataInicial}/${data.dataFinal}/${data.ordenarPor}`);
    }

    const onSubmitAnaliseReceitaPorCompradores = async (data) => {
        window.open(`/relatorio/analiseReceitaPorCompradores/${data.clienteId}/${data.tipoData}/${data.dataInicial}/${data.dataFinal}/${data.ordenarPor}`);
    }

    const onSubmitListaPedidosPorPeriodo = async (data) => {
        window.open(`/relatorio/listaPedidos/${data.tipoData}/${data.dataInicial}/${data.dataFinal}/${data.usarValoresFaturados}/${status3.join('')}`);
    }

    const onSubmitListaPedidosCanceladosPorPeriodo = async (data) => {
        window.open(`/relatorio/listaPedidosCancelados/${data.tipoData}/${data.dataInicial}/${data.dataFinal}`);
    }

    const onSubmitListaPedidosPorClientePeriodo = async (data) => {
        window.open(`/relatorio/listaPedidos/${data.tipoData}/${data.dataInicial}/${data.dataFinal}/listapedidosporclienteperiodo/${data.clienteId}/${data.usarValoresFaturados}/${status4.join('')}`);
    }

    const onSubmitListaPedidosPorFornecedorPeriodo = async (data) => {
        window.open(`/relatorio/listaPedidos/${data.tipoData}/${data.dataInicial}/${data.dataFinal}/listapedidosporfornecedorperiodo/${data.fornecedorId}/${data.usarValoresFaturados}/${status5.join('')}`);
    }

    const onSubmitListaPedidosPorCompradorPeriodo = async (data) => {
        window.open(`/relatorio/listaPedidos/${data.tipoData}/${data.dataInicial}/${data.dataFinal}/listapedidosporcompradorperiodo/${data.compradorId}/${data.usarValoresFaturados}/${status6.join('')}`);
    }

    async function handleInputChangePedidoItem(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (value) {
            await api.get(`/Comprador/${value}/todos`).then(response => {
                setCompradores(response ? response.data : null);
            });
        }
    }

    const handleChange3 = (selectedOptions) => {
        setStatus3(selectedOptions.map(({ value }) => value))
    }

    const handleChange4 = (selectedOptions) => {
        setStatus4(selectedOptions.map(({ value }) => value))
    }

    const handleChange5 = (selectedOptions) => {
        setStatus5(selectedOptions.map(({ value }) => value))
    }

    const handleChange6 = (selectedOptions) => {
        setStatus6(selectedOptions.map(({ value }) => value))
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div>
                <div id="accordion">
                    {relatorios.filter(r => r.nome === 'receitas_por_data').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Receita por tipo de Data (Compra / Previsão de Faturamento / Faturamento)
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={1} onSubmit={handleSubmit(onSubmitReceitaPorTipoData)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-2">
                                                <label>Tipo data</label>
                                                <select id="tipoData" name="tipoData" ref={register()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="0">Compra</option>
                                                    <option value="1">Previsão Faturamento</option>
                                                    <option value="2">Faturamento</option>
                                                </select>
                                                {errors.tipoData && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label>Ordenar Por</label>
                                                <select id="ordenarPor" name="ordenarPor" ref={register()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="1">Nome Cliente</option>
                                                    <option value="2">Quantidade de Pedidos</option>
                                                    <option value="3">Peças</option>
                                                    <option value="4">Custo Liquido</option>
                                                    <option value="9">Valor Comissão</option>
                                                </select>
                                                {errors.ordenarPor && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={register()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={register()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'analise_receita_por_compradores').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Análise de Receita por Compradores
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={2} onSubmit={handleSubmit2(onSubmitAnaliseReceitaPorCompradores)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-2">
                                                <label>Cliente</label>
                                                <select id="clienteId" name="clienteId" ref={register2()} className="form-control" required>
                                                    <option value="00000000-0000-0000-0000-000000000000">Todos</option>
                                                    {
                                                        clientes && clientes.map((cliente, index) => (
                                                            <option key={index} value={cliente.id}>{cliente.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Tipo data</label>
                                                <select id="tipoData" name="tipoData" ref={register2()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="0">Compra</option>
                                                    <option value="1">Previsão Faturamento</option>
                                                    <option value="2">Faturamento</option>
                                                </select>
                                                {errors2.tipoData && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Ordenar Por</label>
                                                <select id="ordenarPor" name="ordenarPor" ref={register2()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="1">Nome Cliente</option>
                                                    <option value="2">Nome Comprador</option>
                                                    <option value="3">Quantidade de Pedidos</option>
                                                    <option value="4">Peças</option>
                                                    <option value="5">Custo Liquido</option>
                                                    <option value="10">Valor Comissão</option>
                                                </select>
                                                {errors2.ordenarPor && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={register2()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={register2()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'lista_pedidos_por_periodo').length > 0 &&
                        <div>
                            <div className="card">
                                <div className="card-header" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Lista de Pedidos
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div className="card-body">
                                        <form key={3} onSubmit={handleSubmit3(onSubmitListaPedidosPorPeriodo)}>
                                            <div className="form-row">
                                                <div className="form-group col-md-2">
                                                    <label>Tipo data</label>
                                                    <select id="tipoData" name="tipoData" ref={register3()} className="form-control" required>
                                                        <option value=""></option>
                                                        <option value="0">Compra</option>
                                                        <option value="1">Previsão Faturamento</option>
                                                        <option value="2">Faturamento</option>
                                                    </select>
                                                    {errors3.tipoData && <span className="text-danger ml-2">*</span>}
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Data Inicial</label>
                                                    <input type="date" name="dataInicial" className="form-control" ref={register3()} id="dataInicial" placeholder="Data Inicial" required />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Data Final</label>
                                                    <input type="date" name="dataFinal" className="form-control" ref={register3()} id="dataFinal" placeholder="Data Final" required />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Usar Valor Faturado?</label>
                                                    <input type="checkbox" name="usarValoresFaturados" className="form-control" ref={register3()} id="usarValoresFaturados" placeholder="Usar Valor Faturado" />
                                                </div>
                                                <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                    <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md">
                                                    <label>Status</label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        onChange={handleChange3}
                                                        defaultValue={[options[0], options[1], options[2], options[3], options[4]]}
                                                        isMulti
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingSeven" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Lista de Pedidos Cancelados
                                </div>
                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                    <div className="card-body">
                                        <form key={5} onSubmit={handleSubmit1(onSubmitListaPedidosCanceladosPorPeriodo)}>
                                            <div className="form-row">
                                                <div className="form-group col-md-2">
                                                    <label>Tipo data</label>
                                                    <select id="tipoData" name="tipoData" ref={register1()} className="form-control" required>
                                                        <option value=""></option>
                                                        <option value="0">Compra</option>
                                                        <option value="1">Previsão Faturamento</option>
                                                        <option value="2">Faturamento</option>
                                                    </select>
                                                    {errors1.tipoData && <span className="text-danger ml-2">*</span>}
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Data Inicial</label>
                                                    <input type="date" name="dataInicial" className="form-control" ref={register1()} id="dataInicial" placeholder="Data Inicial" required />
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Data Final</label>
                                                    <input type="date" name="dataFinal" className="form-control" ref={register1()} id="dataFinal" placeholder="Data Final" required />
                                                </div>
                                                <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                    <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'lista_pedidos_por_cliente_periodo').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingFour" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Lista de Pedidos por Cliente
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={4} onSubmit={handleSubmit4(onSubmitListaPedidosPorClientePeriodo)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label>Cliente</label>
                                                <select id="clienteId" name="clienteId" ref={register4()} className="form-control" required>
                                                    <option value="">Cliente</option>
                                                    {
                                                        clientes && clientes.map((cliente, index) => (
                                                            <option key={index} value={cliente.id}>{cliente.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Tipo data</label>
                                                <select id="tipoData" name="tipoData" ref={register4()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="0">Compra</option>
                                                    <option value="1">Previsão Faturamento</option>
                                                    <option value="2">Faturamento</option>
                                                </select>
                                                {errors4.tipoData && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={register4()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={register4()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Usar Valor Faturado?</label>
                                                <input type="checkbox" name="usarValoresFaturados" className="form-control" ref={register4()} id="usarValoresFaturados" placeholder="Usar Valor Faturado" />
                                            </div>
                                            <div className="form-group col-md">
                                                <label>Status</label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={handleChange4}
                                                    defaultValue={[options[0], options[1], options[2], options[3], options[4]]}
                                                    isMulti
                                                    options={options}
                                                />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'lista_pedidos_por_fornecedor_periodo').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingFive" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Lista de Pedidos por Fornecedor
                            </div>
                            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={4} onSubmit={handleSubmit5(onSubmitListaPedidosPorFornecedorPeriodo)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label>Fornecedor</label>
                                                <select id="fornecedorId" name="fornecedorId" ref={register5()} className="form-control" required>
                                                    <option value="">Fornecedor</option>
                                                    {
                                                        fornecedores && fornecedores.map((fornecedor, index) => (
                                                            <option key={index} value={fornecedor.id}>{fornecedor.razaoSocial}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Tipo data</label>
                                                <select id="tipoData" name="tipoData" ref={register5()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="0">Compra</option>
                                                    <option value="1">Previsão Faturamento</option>
                                                    <option value="2">Faturamento</option>
                                                </select>
                                                {errors5.tipoData && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={register5()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={register5()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Usar Valor Faturado?</label>
                                                <input type="checkbox" name="usarValoresFaturados" className="form-control" ref={register5()} id="usarValoresFaturados" placeholder="Usar Valor Faturado" />
                                            </div>
                                            <div className="form-group col-md">
                                                <label>Status</label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={handleChange5}
                                                    defaultValue={[options[0], options[1], options[2], options[3], options[4]]}
                                                    isMulti
                                                    options={options}
                                                />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                    {relatorios.filter(r => r.nome === 'lista_pedidos_por_comprador_periodo').length > 0 &&
                        <div className="card">
                            <div className="card-header" id="headingSix" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Lista de Pedidos por Comprador
                            </div>
                            <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                <div className="card-body">
                                    <form key={4} onSubmit={handleSubmit6(onSubmitListaPedidosPorCompradorPeriodo)}>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label>Cliente</label>
                                                <select id="clienteId" name="clienteId" ref={register6()} onChange={handleInputChangePedidoItem} className="form-control" required>
                                                    <option value="">Cliente</option>
                                                    {
                                                        clientes && clientes.map((cliente, index) => (
                                                            <option key={index} value={cliente.id}>{cliente.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Comprador</label>
                                                <select id="compradorId" name="compradorId" ref={register6()} className="form-control" required>
                                                    <option value="">Comprador</option>
                                                    {
                                                        compradores && compradores.map((comprador, index) => (
                                                            <option key={index} value={comprador.id}>{comprador.nome}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Tipo data</label>
                                                <select id="tipoData" name="tipoData" ref={register6()} className="form-control" required>
                                                    <option value=""></option>
                                                    <option value="0">Compra</option>
                                                    <option value="1">Previsão Faturamento</option>
                                                    <option value="2">Faturamento</option>
                                                </select>
                                                {errors6.tipoData && <span className="text-danger ml-2">*</span>}
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Inicial</label>
                                                <input type="date" name="dataInicial" className="form-control" ref={register6()} id="dataInicial" placeholder="Data Inicial" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Data Final</label>
                                                <input type="date" name="dataFinal" className="form-control" ref={register6()} id="dataFinal" placeholder="Data Final" required />
                                            </div>
                                            <div className="form-group col-md-2">
                                                <label>Usar Valor Faturado?</label>
                                                <input type="checkbox" name="usarValoresFaturados" className="form-control" ref={register6()} id="usarValoresFaturados" placeholder="Usar Valor Faturado" />
                                            </div>
                                            <div className="form-group col-md">
                                                <label>Status</label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={handleChange6}
                                                    defaultValue={[options[0], options[1], options[2], options[3], options[4]]}
                                                    isMulti
                                                    options={options}
                                                />
                                            </div>
                                            <div className="form-group col-md-2" style={{ paddingTop: "33px" }}>
                                                <button type="submit" className="form-control btn btn-primary">Visualizar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

export default Menu;