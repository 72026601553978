import React, { Component } from 'react';
import api from '../../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NovoProduto from "./novoProduto";
import EditarProduto from "./editarProduto";
import Erros from '../../../components/Erro';

class Produto extends Component {
    state = {
        localizarNome: "",
        fornecedor: {},
        produtos: []
    }

    async componentDidMount() {
        await api.get(`/Fornecedor/id/${this.props.match.params.idFornecedor}`).then(response => {
            this.setState({ fornecedor: response ? response.data : null });
        });

        await api.get(`/Produto/${this.props.match.params.idFornecedor}/todos`).then(response => {
            this.setState({ produtos: response ? response.data : null });
        });
    }

    handleInputLocalizarNome = e => {
        this.setState({ localizarNome: e.currentTarget.value });
    }

    handleLocalizar = async e => {
        e.preventDefault();
        let urlBuscarPor = 'nome';
        let valorBuscar = this.state.localizarNome === '' ? '%' : this.state.localizarNome;
        if (this.state.localizarNome.indexOf('R:') === 0) {
            urlBuscarPor = 'referencia';
            valorBuscar = valorBuscar.substr(2, valorBuscar.length - 2);
            valorBuscar = valorBuscar === '' ? '%' : valorBuscar;
            await api.get(`/Produto/${this.props.match.params.idFornecedor}/${urlBuscarPor}/${encodeURIComponent(valorBuscar)}`).then(response => {
                this.setState({ produtos: [ response ? response.data : null ] });
            });
        } else {
            await api.get(`/Produto/${this.props.match.params.idFornecedor}/${urlBuscarPor}/${encodeURIComponent(valorBuscar)}`).then(response => {
                this.setState({ produtos:  response ? response.data : null });
            });
        }        
    }

    handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete('/Produto?id=' + id).then(response => {
                if (response) {
                    this.setState({ produtos: this.state.produtos.filter(f => f.id !== id) });
                }
            });
        }
    }

    render() {
        const { localizarNome, fornecedor, produtos } = this.state;
        return (
            <div className="container" style={{ marginTop: 20 }}>
                <div className="card">
                    <div className="card-header">
                    <Link to={`/fornecedores/${fornecedor.id}`}><h5>{fornecedor.razaoSocial}</h5></Link>
                                                        
                        
                        Produtos
                    </div>
                    <Erros />
                    <div className="card-body">
                        <h5 className="card-title">Localizar</h5>
                        <div>
                            <form onSubmit={this.handleLocalizar}>
                                <div className="form-row">
                                    <div className="col-md-4 pt-2">
                                        <label className="sr-only">Nome/Referência</label>
                                        <input type="text" onChange={this.handleInputLocalizarNome} value={localizarNome} className="form-control" id="inputLocalizar" placeholder="Nome ou Referência (Ex: R:65454)" />
                                    </div>
                                    <div className="col-md-1 pt-2">
                                        <div style={{ display: "inline-flex" }}>
                                            <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                            <Link className="btn btn-success mx-sm-4 ml-2 mb-2" to={`/produtos/novo/${fornecedor.id}`}>Novo Produto</Link>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Referência</th>
                                            <th scope="col">Descrição</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {produtos && produtos.map(produto => (
                                            <tr key={produto.id}>
                                                <td>{produto.referencia}</td>
                                                <td>{produto.descricao}</td>
                                                <td style={{ textAlign: "right" }}>
                                                    <div style={{ display: "inline-flex" }}>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={`/produtos/editar/${produto.fornecedorId}/${produto.id}`}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                                        <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => this.handleExcluir(produto.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Produto, NovoProduto, EditarProduto };