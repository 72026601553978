import React, { Component } from 'react';
import api from '../../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Erros from '../../../components/Erro';
import NovoContato from "./novoContato";
import EditarContato from "./editarContato";


class Contato extends Component {
    state = {
        localizarNome: "",
        fornecedor: {},
        contatos: []
    }

    async componentDidMount() {
        await api.get(`/Fornecedor/id/${this.props.match.params.idFornecedor}`).then(response => {
            this.setState({ fornecedor: response ? response.data : null });
        });

        await api.get(`/Contato/${this.props.match.params.idFornecedor}/todos`).then(response => {
            this.setState({ contatos: response ? response.data : null });
        });
    }

    handleInputLocalizarNome = e => {
        this.setState({ localizarNome: e.currentTarget.value });
    }

    handleLocalizar = async e => {
        e.preventDefault();
        await api.get(`/Contato/${this.props.match.params.idFornecedor}/nome/${encodeURIComponent(this.state.localizarNome === '' ? '%' : this.state.localizarNome)}`).then(response => {
            this.setState({ contatos: response ? response.data : null });
        });
    }

    handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete('/Contato?id=' + id).then(response => {
                if (response) {
                    this.setState({ contatos: this.state.contatos.filter(f => f.id !== id) });
                }
            });
        }
    }

    render() {
        const { localizarNome, fornecedor, contatos } = this.state;
        return (
            <div className="container" style={{ marginTop: 20 }}>
                <div className="card">
                    <div className="card-header">
                        <Link to={`/fornecedores/${fornecedor.id}`}><h5>{fornecedor.razaoSocial}</h5></Link>
                        Contatos
                    </div>
                    <Erros />
                    <div className="card-body">
                        <h5 className="card-title">Localizar</h5>
                        <div>
                            <form onSubmit={this.handleLocalizar}>
                                <div className="form-row">
                                    <div className="col-md-4 pt-2">
                                        <label className="sr-only">Nome</label>
                                        <input type="text" onChange={this.handleInputLocalizarNome} value={localizarNome} className="form-control" id="inputLocalizar" placeholder="Nome" />
                                    </div>
                                    <div className="col-md-1 pt-2">
                                        <div style={{ display: "inline-flex" }}>
                                            <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                            <Link className="btn btn-success mx-sm-4 ml-2 mb-2" to={`/contatos/novo/${fornecedor.id}`}>Novo Contato</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Telefone</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contatos && contatos.map(contato => (
                                            <tr key={contato.id}>
                                                <td>{contato.nome}</td>
                                                <td>{contato.telefone}</td>
                                                <td style={{ textAlign: "right" }}>
                                                    <div style={{ display: "inline-flex" }}>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={`/contatos/editar/${contato.fornecedorId}/${contato.id}`}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                                        <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => this.handleExcluir(contato.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Contato, NovoContato, EditarContato };