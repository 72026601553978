export const permissoes = [{
    label: "Fornecedor",
    name: "cadFornecedor"
}, {
    label: "Produto",
    name: "cadProduto"
}, {
    label: "Cliente",
    name: "cadCliente"
}, {
    label: "Grupo",
    name: "cadGrupo"
}, {
    label: "Subgrupo",
    name: "cadSubGrupo"
}, {
    label: "Material",
    name: "cadMaterial"
}, {
    label: "Financeiro",
    name: "cadFinanceiro"
}, {
    label: "Relatórios Financeiro",
    name: "relFinanceiro"
}];