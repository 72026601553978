import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../../components/Erro';
import { mask, unMask } from 'remask';

function NovaComprador() {
    let history = useHistory();
    let { idCliente } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [cliente, setCliente] = useState([]);
    const [telefone, setTelefone] = useState("");

    useEffect(() => {
        async function fetchData() {
            await api.get('/Cliente/id/' + idCliente).then(response => {
                setCliente(response ? response.data : null);
            });            
        }
        fetchData();
    }, [idCliente]);

    const onSubmit = async data => {
        window.scrollTo(0, 0);
        data.telefone = unMask(data.telefone);
        await api.post('/Comprador', data).then(response => {
            if (response) {
                history.push(`/compradores/${idCliente}`);
            }
        });
    };

    const onChangeMask = (value, maskFormat, setMethod) => {
        setMethod(mask(unMask(value), maskFormat));
    }

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">
                    <h5>{cliente ? cliente.nome : ""}</h5>
                    Compradores
                </div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Nova Loja</h5>
                    <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden" defaultValue={idCliente} name="clienteId" ref={register} />
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Nome</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="nome" id="Nome" ref={register({ required: true })} placeholder="Nome" />
                                    {errors.nome && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Telefone</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="telefone" value={telefone} onChange={(e) => onChangeMask(e.target.value, ['(99) 99999-9999'], setTelefone)} id="telefone" ref={register} placeholder="Telefone" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">E-Mail</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="email" id="email" ref={register} placeholder="E-Mail" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Aniversário</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="aniversario" id="aniversario" ref={register} placeholder="Aniversário" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to={`/compradores/${idCliente}`} >Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NovaComprador;