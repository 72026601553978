import React, { Component } from 'react';
import api from '../../services/api';
import Logo from '../../assets/logo.png';
import { LandscapeOrientation } from '../../utils/landscapeOrientation';
import '../../assets/relatorios.css';
class PedidoCompleto extends Component {
    state = {
        pedido: [],
        faturado: false
    };

    FormataStringData = data => {
        if (data) {
            var ano = data.split("-")[0];
            var mes = data.split("-")[1];
            var dia = data.split("-")[2];
            return ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ano;
        }
    }

    async componentDidMount() {
        await api.get(`/Pedido/id/${this.props.match.params.id}`).then(response => {
            this.setState({ pedido: response ? response.data : null });
        });
        window.print();
    }
    render() {
        const { pedido, faturado } = this.state;
        return (

            <div style={{ width: '100%', paddingLeft: 15, paddingRight: 15 }}>
                {pedido.numero &&
                    <>
                        <LandscapeOrientation />
                        <div style={{fontSize: 12 }} className="row pr-3 pl-3 pt-3">
                            <div style={{ width: 150 }}>
                                <img className='logo' src={Logo} alt="Logo" />
                            </div>
                            <div className="pl-2">
                                <b>Cliente:</b> {pedido.cliente?.nome}<br />
                                <b>Fornecedor:</b> {pedido.fornecedor?.razaoSocial}<br />
                                <b>Status:</b> {pedido.statusPedidoLabel}
                            </div>
                            <div className="pl-2">
                                <b>Data Faturamento:</b> {this.FormataStringData(pedido.dataFaturamento)}<br />
                                <b>Data Compra:</b> {this.FormataStringData(pedido.dataCompra)}<br />
                                <b>Observações:</b> {pedido.observacoes}
                            </div>
                            <div className="pl-2">
                                <b>Número Pedido:</b> {pedido.numero}<br />
                                <b>Número Pedido Cliente:</b> {pedido.numeroPedidoCliente}<br />
                                <b>Comprador(a):</b> {pedido.comprador?.nome}<br />                                
                            </div>
                        </div>
                        <div className="relatorio table-responsive mt-2">
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th scope="col">Ref</th>
                                        <th scope="col">Grupo</th>
                                        <th scope="col">Subgrupo</th>
                                        <th scope="col">Material</th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col">Cor</th>
                                        <th scope="col" style={{ textAlign: "center" }} colSpan="2">Grade</th>
                                        <th scope="col">Custo Liq</th>
                                        <th scope="col">Lojas</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Custo Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pedido.pedidoItens && pedido.pedidoItens.map(itens => (
                                        <tr key={itens.id} style={{ fontSize: 12 }}>
                                            <td>{itens.produto.referencia}</td>
                                            <td>{itens.produto.grupo.nome}</td>
                                            <td>{itens.produto.subGrupo.nome}</td>
                                            <td>{itens.material.nome}</td>
                                            <td>{itens.produto.descricao}</td>
                                            <td>{itens.cor}</td>
                                            <td style={{ verticalAlign: "middle" }}><b>{itens.tipoGrade}</b></td>
                                            <td style={{ padding: 0, borderTop: 0, borderBottom: 0, borderRight: 0, verticalAlign: "top" }}>
                                                <table style={{ width: '100%', borderBottom: '0px solid', borderLeft: '0px solid', borderRight: '0px solid', borderTop: '1px solid' }}>
                                                    <thead>
                                                        <tr style={{ backgroundColor: 'transparent' }}>
                                                            {itens.valoresGrade.split('|').map((g, index) => (
                                                                <th key={index} style={index === 0 ? { borderRight: '0px solid', borderTop: '0px solid', borderLeft: '0px solid' } : { borderRight: '0px solid', borderTop: '0px solid', borderLeft: '1px solid' }}>
                                                                    {g.split('=')[0]}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr style={{ backgroundColor: 'transparent' }}>
                                                            {itens.valoresGrade.split('|').map((g, index) => (
                                                                <td key={index} style={index === 0 ? { borderRight: '0px solid', borderTop: '0px solid', borderBottom: '2px solid', borderLeft: '0px solid' } : { borderRight: '0px solid', borderBottom: '2px solid', borderTop: '0px solid', borderLeft: '1px solid' }}>
                                                                    {g.split('=')[1]}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                {itens.custoLiquido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </td>

                                            <td style={{ padding: 0, borderTop: 0, borderBottom: 0, borderRight: 0, verticalAlign: "top" }}>
                                                <table style={{ width: '100%', borderBottom: '0px solid', borderLeft: '0px solid', borderRight: '0px solid', borderTop: '1px solid' }}>
                                                    <thead>
                                                        <tr style={{ backgroundColor: 'transparent' }}>
                                                            {itens.pedidoItemLojas.map((pedidoItemLoja, index) => (
                                                                <th key={index} style={index === 0 ? { borderRight: '0px solid', borderTop: '0px solid', borderLeft: '0px solid' } : { borderRight: '0px solid', borderTop: '0px solid', borderLeft: '1px solid' }}>
                                                                    L{pedidoItemLoja.loja.numeroFilial}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ backgroundColor: 'transparent' }}>
                                                            {itens.pedidoItemLojas.map((pedidoItemLoja, index) => (
                                                                <td key={index} style={index === 0 ? { borderRight: '0px solid', borderTop: '0px solid', borderBottom: '2px solid', borderLeft: '0px solid' } : { borderRight: '0px solid', borderTop: '0px solid', borderBottom: '2px solid', borderLeft: '1px solid' }}>
                                                                    {pedidoItemLoja.quantidade}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>

                                            <td>
                                                {!faturado ? itens.total : itens.totalFaturado}
                                            </td>

                                            <td>
                                                {(!faturado ? itens.custoTotalLiquido : itens.custoTotalLiquidoFaturado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </td>

                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="10"></td>
                                        <td>{!faturado ? pedido.totalVenda : pedido.totalFaturado}</td>
                                        <td>{(!faturado ? pedido.custoLiquidoVenda : pedido.custoLiquidoFaturado).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </>
                }
            </div>
        );
    }
}

export default PedidoCompleto;