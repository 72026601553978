import React, { Component } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NovoParceiro from "./novoParceiro";
import EditarParceiro from "./editarParceiro";
import Erros from '../../components/Erro'

class Parceiro extends Component {
    state = {
        localizarNome: "",
        parceiros: []
    }

    async componentDidMount() {
        if (this.props.match.params.id) {
            await api.get(`/Parceiro/id/${this.props.match.params.id}`).then(response => {
                this.setState({ parceiros: [response ? response.data : null] });
            });
        } else {
            await api.get('/Parceiro/todos').then(response => {
                this.setState({ parceiros: response ? response.data : null });
            });
        }
    }

    handleInputLocalizarNome = e => {
        this.setState({ localizarNome: e.currentTarget.value });
    }

    handleLocalizar = async e => {
        e.preventDefault();
        await api.get('/Parceiro/nome/' + encodeURIComponent(this.state.localizarNome === '' ? '%' : this.state.localizarNome)).then(response => {
            this.setState({ parceiros: response ? response.data : null });
            this.props.history.push("/parceiros");
        });
    }

    handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete('/Parceiro?id=' + id).then(response => {
                if (response) {
                    this.setState({ parceiros: this.state.parceiros.filter(f => f.id !== id) });
                }
            });
        }
    }

    render() {
        const { localizarNome, parceiros } = this.state;
        return (
            <div className="container" style={{ marginTop: 20 }}>
                <div className="card">
                    <div className="card-header">Parceiros</div>
                    <Erros />
                    <div className="card-body">
                        <h5 className="card-title">Localizar</h5>
                        <div>
                            <form onSubmit={this.handleLocalizar}>
                                <div className="form-row">
                                    <div className="col-md-4 pt-2">
                                        <label className="sr-only">Nome</label>
                                        <input type="text" onChange={this.handleInputLocalizarNome} value={localizarNome} className="form-control" id="inputLocalizar" placeholder="Nome" />
                                    </div>
                                    <div className="col-md-1 pt-2">
                                        <div style={{ display: "inline-flex" }}>
                                            <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                            <Link className="btn btn-success mx-sm-4 ml-2 mb-2" to="/parceiros/novo">Novo</Link>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Percentual Padrão</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {parceiros && parceiros.map(parceiro => (
                                            <tr key={parceiro.id}>
                                                <td>{parceiro.nome}</td>
                                                <td>{parceiro.percentalPadrao.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' })}</td>
                                                <td style={{ textAlign: "right" }}>
                                                    <div style={{ display: "inline-flex" }}>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={'/parceiros/editar/' + parceiro.id}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} title="Editar" /></Link>
                                                        <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => this.handleExcluir(parceiro.id)} title="Excluir" ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Parceiro, NovoParceiro, EditarParceiro };