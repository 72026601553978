import React from 'react';
import Routes from './router';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Hender from './components/Navegacao/hender';
import store from './store'
require('dotenv').config();
function App() {
  const rotasNaoHender = ['/relatorio/', '/login'];
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          {rotasNaoHender.filter(r => window.location.pathname.includes(r)).length === 0 && <Hender />}
          <Routes />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
