import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import store from '../../../store';
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../../components/Erro';

function NovoClienteFornecedor() {
    let history = useHistory();
    let { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [clienteFornecedor, setClienteFornecedor] = useState({});

    useEffect(() => {
        async function fetchData() {
            if (id) {
                await api.get(`/ClienteFornecedor/id/${id}`).then(response => {
                    setClienteFornecedor(response ? response.data : null);
                });
            }
        }
        fetchData();
    }, [id])

    const successMenssage = () => {
        const { dispatch } = store;
        dispatch({
            type: 'ADD_ERRO_MENSAGEM',
            error: {
                tipo: 'alert-success',
                hideMessage: true,
                errorMessage: [{ mensagem: 'Registro Salvo com sucesso!' }]
            }
        });
    }

    const onSubmit = async data => {
        if (!clienteFornecedor.id) {
            await api.post('/ClienteFornecedor', data).then(response => {
                if (response) {
                    setClienteFornecedor(response.data);
                    history.push(`/clienteFornecedor/${response.data.id}`)
                    successMenssage();
                }
            });
        } else {
            data.id = clienteFornecedor.id;
            await api.put('/ClienteFornecedor', data).then(response => {
                if (response) {
                    setClienteFornecedor(response.data);
                    successMenssage();
                }
            });
        }
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {!clienteFornecedor.id ? 'Novo Cliente/Fornecedor' : `Editar Cliente/Fornecedor`}
                    </div>
                    <Erros />
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Nome</label>
                                        <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                            <input type="text" className="form-control" name="nome" id="nome" defaultValue={clienteFornecedor.nome} ref={register({ required: true })} placeholder="Nome" />
                                            {errors.nome && <span className="text-danger ml-2">*</span>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">CPF/CNPJ (Apenas Números)</label>
                                        <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                            <input type="text" className="form-control" name="cpfCnpj" defaultValue={clienteFornecedor.cpfCnpj}  id="cpfCnpj" ref={register} placeholder="CPF/CNPJ" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-2 pt-2">
                                            <div style={{ display: "inline-flex" }}>
                                                <button type="submit" className="btn btn-primary mb-2 mt-1">Salvar</button>
                                                <Link className="btn btn-danger mx-sm-3 ml-2 mb-2 mt-1" to={`/clienteFornecedor`}>Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default NovoClienteFornecedor;