import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { StatusLacamento, TipoLacamento } from "../../../utils/Utils";
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import Erros from '../../../components/Erro';

function Lancamentos() {
  const [clientesFornecedores, setClientesFornecedores] = useState([]);
  const [naturezasFinanceiras, setNaturezasFinanceiras] = useState([]);
  const [filtroSelecionado, setFiltroSelecionado] = useState("");
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [tipoData, setTipoData] = useState("1");
  const [clienteFornecedorId, setClienteFornecedorId] = useState("");
  const [naturezaFinanceiraId, setNaturezaFinanceiraId] = useState("");
  const [lancamentos, setLancamentos] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await api.get('/ClienteFornecedor/todos').then(response => {
        setClientesFornecedores(response ? response.data : null);
      });
      await api.get('/NaturezaFinanceira/todos').then(response => {
        setNaturezasFinanceiras(response ? response.data : null);
      });
      await api.get('/Lancamento/ultimosLancados').then(response => {
        setLancamentos(response ? response.data : null);
      });
    }
    fetchData();
    document.getElementById("tipoDataVencimento").checked = true;
  }, [])

  const setFiltroValor = (e) => {
    switch (e.currentTarget.name) {
      case 'dataInicial':
        setDataInicial(e.currentTarget.value);
        localStorage.setItem('dataInicial', e.currentTarget.value);
        break;
      case 'dataFinal':
        setDataFinal(e.currentTarget.value);
        localStorage.setItem('dataFinal', e.currentTarget.value);
        break;
      case 'clienteFornecedorId':
        setClienteFornecedorId(e.currentTarget.value);
        localStorage.setItem('clienteFornecedorId', e.currentTarget.value);
        break;
      case 'naturezaFinanceiraId':
        setNaturezaFinanceiraId(e.currentTarget.value);
        localStorage.setItem('naturezaFinanceiraId', e.currentTarget.value);
        break;
      case 'tipoData':
        setTipoData(e.currentTarget.value);
        localStorage.setItem('tipoData', e.currentTarget.value);
        break;
      default:
    }
  }

  const getLancamentos = async (e) => {
    e.preventDefault();
    if (filtroSelecionado === 'data' && dataInicial !== '' && dataFinal !== '') {
      await api.get(`/Lancamento/obterPorData/${tipoData}/${dataInicial}/${dataFinal}`).then(response => {
        setLancamentos(response ? response.data : null);
        return;
      });
    }

    if (filtroSelecionado === 'clienteFornecedor' && clienteFornecedorId !== '' && dataInicial !== '' && dataFinal !== '') {
      await api.get(`/Lancamento/clienteFornecedorData/${clienteFornecedorId}/${tipoData}/${dataInicial}/${dataFinal}`).then(response => {
        setLancamentos(response ? response.data : null);
        return;
      });
    }

    if (filtroSelecionado === 'clienteFornecedor' && clienteFornecedorId !== '' && (dataInicial === '' || dataFinal === '')) {
      await api.get(`/Lancamento/clienteFornecedor/${clienteFornecedorId}`).then(response => {
        setLancamentos(response ? response.data : null);
        return;
      });
    }

    if (filtroSelecionado === 'naturezaFinanceira' && naturezaFinanceiraId !== '' && dataInicial !== '' && dataFinal !== '') {
      await api.get(`/Lancamento/naturezaFinanceiraData/${naturezaFinanceiraId}/${tipoData}/${dataInicial}/${dataFinal}`).then(response => {
        setLancamentos(response ? response.data : null);
        return;
      });
    }

    if (filtroSelecionado === 'naturezaFinanceira' && naturezaFinanceiraId !== '' && (dataInicial === '' || dataFinal === '')) {
      await api.get(`/Lancamento/naturezaFinanceira/${naturezaFinanceiraId}`).then(response => {
        setLancamentos(response ? response.data : null);
        return;
      });
    }
  }

  const onChangeFiltro = (e) => {
    setFiltroSelecionado(e.target.value);
    let filtro = e.target.value;
    let filtros = document.getElementById("filtros");
    let tiposData = document.getElementById("tiposData");
    let clienteFornecedor = document.getElementById("clienteFornecedor");
    let naturezaFinanceira = document.getElementById("naturezaFinanceira");
    setDataInicial(localStorage.getItem('dataInicial') ?? '');
    setDataFinal(localStorage.getItem('dataFinal') ?? '');
    switch (filtro) {
      case 'data':
        filtros.classList.remove('d-none');
        tiposData.classList.remove('d-none');
        clienteFornecedor.classList.add('d-none');
        naturezaFinanceira.classList.add('d-none');
        break;
      case 'clienteFornecedor':
        filtros.classList.remove('d-none');
        tiposData.classList.remove('d-none');
        clienteFornecedor.classList.remove('d-none');
        naturezaFinanceira.classList.add('d-none');
        setClienteFornecedorId(localStorage.getItem('clienteFornecedorId') ?? '');
        break;
      case 'naturezaFinanceira':
        filtros.classList.remove('d-none');
        tiposData.classList.remove('d-none');
        naturezaFinanceira.classList.remove('d-none');
        clienteFornecedor.classList.add('d-none');
        setNaturezaFinanceiraId(localStorage.getItem('naturezaFinanceiraId') ?? '');
        break;
      default:
        filtros.classList.add('d-none');
        tiposData.classList.add('d-none');
    }
  }

  const handleExcluir = async id => {
    if (window.confirm('Dejesa realmente excluir?')) {
      await api.delete(`/Lancamento/lancamento/id/${id}`).then(response => {
        if (response) {
          setLancamentos(lancamentos.filter(l => l.id !== id));
        }
      });
    }
  }

  return (
    <div>
      <div className="container" style={{ marginTop: 20 }}>
        <div className="card">
          <div className="card-header">Lançamentos</div>
          <Erros />
          <div className="card-body">
            <h5 className="card-title">Localizar</h5>
            <div>
              <form onSubmit={getLancamentos} >
                <div className="form-row">
                  <div className="col-md-4 mt-3">
                    <select id="tipoFiltros" name="tipoFiltros" onChange={onChangeFiltro} className="form-control" >
                      <option value="">Selecione um tipo de filtro</option>
                      <option value="data">Data</option>
                      <option value="clienteFornecedor">Cliente/Fornecedor</option>
                      <option value="naturezaFinanceira">Natureza Financeira</option>
                    </select>
                  </div>
                  <div className="col-md-1 mt-3">
                    <div style={{ display: "inline-flex" }}>
                      <Link className="btn btn-success  mx-sm-1 ml-2 mb-2" to={`/financeiro/lancamento`}>Novo</Link>
                    </div>
                  </div>
                </div>
                <div id="tiposData" className="form-row d-none">
                  <div style={{ display: "inline-flex" }}>
                    <input type="radio" id="tipoDataVencimento" onChange={setFiltroValor} className="mx-sm-1 ml-2 mt-2" name="tipoData" value="1" />
                    <label htmlFor="tipoDataVencimento" className="mx-sm-1 ml-2 mt-1">Vencimento</label>
                    <input type="radio" id="tipoDataBaixa" onChange={setFiltroValor} className="mx-sm-1 ml-2 mt-2" name="tipoData" value="2" />
                    <label htmlFor="tipoDataBaixa" className="mx-sm-1 ml-2 mt-1">Baixa</label>
                    <input type="radio" id="tipoDataLancamento" onChange={setFiltroValor} className="mx-sm-1 ml-2 mt-2" name="tipoData" value="3" />
                    <label htmlFor="tipoDataLancamento" className="mx-sm-1 ml-2 mt-1">Lançamento</label>
                  </div>
                </div>
                <div id="filtros" className="form-row d-none">
                  <div id="dataInicio" className="col-md-2 mt-1">
                    <input type="date" name="dataInicial" value={dataInicial} className="form-control" id="inputInicial" onChange={setFiltroValor} placeholder="Data Inicial" />
                  </div>
                  <div id="dataFim" className="col-md-2 mt-1">
                    <input type="date" name="dataFinal" value={dataFinal} className="form-control" id="inputFinal" onChange={setFiltroValor} placeholder="Data Final" />
                  </div>
                  <div id="clienteFornecedor" className="col-md-4 mt-1 d-none">
                    <select id="clienteFornecedorId" name="clienteFornecedorId" value={clienteFornecedorId} onChange={setFiltroValor} className="form-control">
                      <option value="">Cliente/Fornecedor</option>
                      {
                        clientesFornecedores && clientesFornecedores.map((clienteFornecedor, index) => (
                          <option key={index} value={clienteFornecedor.id}>{clienteFornecedor.nome}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div id="naturezaFinanceira" className="col-md-4 mt-1 d-none">
                    <select id="naturezaFinanceiraId" name="naturezaFinanceiraId" value={naturezaFinanceiraId} onChange={setFiltroValor} className="form-control">
                      <option value="">Natureza Financeira</option>
                      {
                        naturezasFinanceiras && naturezasFinanceiras.map((naturezaFinanceira, index) => (
                          <option disabled={naturezaFinanceira.sintetico} key={index} value={naturezaFinanceira.id}>{naturezaFinanceira.descricao}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-md-1 mt-1">
                    <div style={{ display: "inline-flex" }}>
                      <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="table-responsive mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Tipo</th>
                      <th scope="col">Status</th>
                      <th scope="col">Cliente/Fornecedor</th>
                      <th scope="col">Centro de Custo</th>
                      <th scope="col">Natureza Financeira</th>
                      <th scope="col">Cliente</th>
                      <th scope="col">Fornecedor</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Valor Baixado</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {lancamentos && lancamentos.map(lancamento => (
                      <tr key={lancamento.id}>
                        <td><TipoLacamento data={lancamento.tipo} /></td>
                        <td><StatusLacamento data={lancamento.status} /> </td>
                        <td>{lancamento.clienteFornecedor.nome}</td>
                        <td>{lancamento.centroCusto.nome}</td>
                        <td>{lancamento.naturezaFinanceira.descricao}</td>
                        <td>{lancamento.pedido && lancamento.pedido.cliente.nome}</td>
                        <td>{lancamento.pedido && lancamento.pedido.fornecedor.razaoSocial}</td>
                        <td>{lancamento.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        <td>{lancamento.valorTotalBaixado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                        <td style={{ textAlign: "right" }}>
                          <div style={{ display: "inline-flex" }}>
                            <Link className="mx-sm-1 ml-2 mb-2" to={`/Financeiro/lancamento/${lancamento.id}`}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                            <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => handleExcluir(lancamento.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lancamentos;