import React from 'react';
import { Switch, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import Main from './pages/Main';
import Login from './pages/Login';
import { Usuario, NovoUsuario, EditarUsuario } from './pages/Usuario';
import { Fornecedor, NovoFornecedor, EditarFornecedor } from './pages/Fornecedor';
import { Cadastro, NovoCadastro, EditarCadastro } from './components/Cadastro';
import { Produto, NovoProduto, EditarProduto } from './pages/Fornecedor/Produto';
import { Contato, NovoContato, EditarContato } from './pages/Fornecedor/Contato';
import { Cliente, NovoCliente, EditarCliente } from './pages/Cliente';
import { Parceiro, NovoParceiro, EditarParceiro } from './pages/Parceiro';
import { Loja, NovoLoja, EditarLoja } from './pages/Cliente/Loja';
import { Comprador, NovoComprador, EditarComprador } from './pages/Cliente/Comprador';
import { Pedido, NovoPedido } from './pages/Pedido';
import { Menu, PedidoCompleto, PedidoResumo, ReceitaPorTipoData, AnaliseReceitaPorCompradores, ListaPedidos, ListaPedidosCancelados } from './pages/Relarorios';
import { Lancamentos, Lancamento } from './pages/Financeiro/Lancamento';
import MenuRelatoriosFinanceito from './pages/Financeiro/Relatorios/menu';
import { ClientesFornecedores, NovoClienteFornecedor } from './pages/Financeiro/ClienteFornecedor/index';
import { NaturezasFinanceiras, NovoNaturezaFinanceira } from './pages/Financeiro/NaturezaFinanceira/index';
import LancamentosEmAberto from './pages/Relarorios/lancamentosEmAberto';
import LancamentosPorNF from './pages/Relarorios/lancamentosPorNF';
import LancamentosPorCC from './pages/Relarorios/lancamentosPorCC';

const isAuthenticated = () => {
  var token = localStorage.getItem("token");
  if (token !== null) {
    var decoded = jwt_decode(token);
    var current_time = new Date().getTime() / 1000;
    if (current_time > decoded.exp) {
      return false;
    }
    return true;
  }
}
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      props => isAuthenticated()
        ? (<Component {...props} />)
        : window.location.replace("/login")
    }
  />
);


export default function Routes() {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Main} />
      <Route path="/login" exact component={Login} />

      <PrivateRoute path="/usuarios" exact component={Usuario} />
      <PrivateRoute path="/usuarios/novo" exact component={NovoUsuario} />
      <PrivateRoute path="/usuarios/editar/:id" exact component={EditarUsuario} />

      <PrivateRoute path="/pedidos" exact component={Pedido} />
      <PrivateRoute path="/pedidos/novo" exact component={NovoPedido} />
      <PrivateRoute path="/pedidos/:id" exact component={NovoPedido} />

      <PrivateRoute path="/financeiro/lancamentos" exact component={Lancamentos} />
      <PrivateRoute path="/financeiro/lancamento" exact component={Lancamento} />
      <PrivateRoute path="/financeiro/lancamento/:id" exact component={Lancamento} />
      <PrivateRoute path="/financeiro/relatorios" exact component={MenuRelatoriosFinanceito} />

      <PrivateRoute path="/clienteFornecedor" exact component={ClientesFornecedores} />
      <PrivateRoute path="/clienteFornecedor/novo" exact component={NovoClienteFornecedor} />
      <PrivateRoute path="/clienteFornecedor/:id" exact component={NovoClienteFornecedor} />

      <PrivateRoute path="/naturezaFinanceira" exact component={NaturezasFinanceiras} />
      <PrivateRoute path="/naturezaFinanceira/novo" exact component={NovoNaturezaFinanceira} />
      <PrivateRoute path="/naturezaFinanceira/:id" exact component={NovoNaturezaFinanceira} />


      /\*Rotas Relatórios\*/
      <PrivateRoute path="/relatorios" exact component={Menu} />
      <PrivateRoute path="/relatorio/pedidoCompleto/:id" exact component={PedidoCompleto} />
      <PrivateRoute path="/relatorio/pedidoResumo/:id" exact component={PedidoResumo} />
      <PrivateRoute path="/relatorio/receitaPorTipoData/:tipoData/:dataInicial/:dataFinal/:ordenarPor" exact component={ReceitaPorTipoData} />
      <PrivateRoute path="/relatorio/analiseReceitaPorCompradores/:clienteId/:tipoData/:dataInicial/:dataFinal/:ordenarPor" exact component={AnaliseReceitaPorCompradores} />

      <PrivateRoute path="/relatorio/listaPedidos/:tipoData/:dataInicial/:dataFinal/:usarValoresFaturados/:status" exact component={ListaPedidos} />
      <PrivateRoute path="/relatorio/listaPedidosCancelados/:tipoData/:dataInicial/:dataFinal" exact component={ListaPedidosCancelados} />
      <PrivateRoute path="/relatorio/listaPedidos/:tipoData/:dataInicial/:dataFinal/:tipoRelatorio/:guid/:usarValoresFaturados/:status" exact component={ListaPedidos} />
      <PrivateRoute path="/relatorio/lancamentosEmAberto/:clienteFornecedorId" exact component={LancamentosEmAberto} />
      <PrivateRoute path="/relatorio/lancamentosPorNF/:naturezaFinanceiraId/:tipoData/:dataInicial/:dataFinal/:clienteFornecedorId" exact component={LancamentosPorNF} />
      <PrivateRoute path="/relatorio/lancamentosPorCC/:centroCustoId/:tipoData/:dataInicial/:dataFinal" exact component={LancamentosPorCC} />

      /\*Rotas Fornecedor\*/
      <PrivateRoute path="/fornecedores" exact component={Fornecedor} />
      <PrivateRoute path="/fornecedores/novo" exact component={NovoFornecedor} />
      <PrivateRoute path="/fornecedores/:id" exact component={Fornecedor} />
      <PrivateRoute path="/fornecedores/editar/:id" exact component={EditarFornecedor} />

      <PrivateRoute path="/produtos/:idFornecedor" exact component={Produto} />
      <PrivateRoute path="/produtos/novo/:idFornecedor" exact component={NovoProduto} />
      <PrivateRoute path="/produtos/editar/:idFornecedor/:id" exact component={EditarProduto} />

      <PrivateRoute path="/contatos/:idFornecedor" exact component={Contato} />
      <PrivateRoute path="/contatos/novo/:idFornecedor" exact component={NovoContato} />
      <PrivateRoute path="/contatos/editar/:idFornecedor/:id" exact component={EditarContato} />

      /\*Rotas Cliente\*/
      <PrivateRoute path="/clientes" exact component={Cliente} />
      <PrivateRoute path="/clientes/novo" exact component={NovoCliente} />
      <PrivateRoute path="/clientes/:id" exact component={Cliente} />
      <PrivateRoute path="/clientes/editar/:id" exact component={EditarCliente} />

      <PrivateRoute path="/lojas/:idCliente" exact component={Loja} />
      <PrivateRoute path="/lojas/novo/:idCliente" exact component={NovoLoja} />
      <PrivateRoute path="/lojas/editar/:idCliente/:id" exact component={EditarLoja} />

      <PrivateRoute path="/compradores/:idCliente" exact component={Comprador} />
      <PrivateRoute path="/compradores/novo/:idCliente" exact component={NovoComprador} />
      <PrivateRoute path="/compradores/editar/:idCliente/:id" exact component={EditarComprador} />

      /\*Rotas Cadastro\*/
      <PrivateRoute path="/cadastro/grupos" exact component={(props) => <Cadastro controller="Grupo" title="Grupos" listName="grupos" {...props} />} />
      <PrivateRoute path="/cadastro/grupos/novo" exact component={(props) => <NovoCadastro controller="Grupo" title="Grupos" listName="grupos" {...props} />} />
      <PrivateRoute path="/cadastro/grupos/editar/:id" exact component={(props) => <EditarCadastro controller="Grupo" title="Grupos" listName="grupos" {...props} />} />

      <PrivateRoute path="/cadastro/subgrupos" exact component={(props) => <Cadastro controller="SubGrupo" title="Subgrupos" listName="subgrupos" {...props} />} />
      <PrivateRoute path="/cadastro/subgrupos/novo" exact component={(props) => <NovoCadastro controller="SubGrupo" title="Subgrupos" listName="subgrupos" {...props} />} />
      <PrivateRoute path="/cadastro/subgrupos/editar/:id" exact component={(props) => <EditarCadastro controller="SubGrupo" title="Subgrupos" listName="subgrupos" {...props} />} />

      <PrivateRoute path="/cadastro/materiais" exact component={(props) => <Cadastro controller="Material" title="Materiais" listName="materiais" {...props} />} />
      <PrivateRoute path="/cadastro/materiais/novo" exact component={(props) => <NovoCadastro controller="Material" title="Materiais" listName="materiais" {...props} />} />
      <PrivateRoute path="/cadastro/materiais/editar/:id" exact component={(props) => <EditarCadastro controller="Material" title="Materiais" listName="materiais" {...props} />} />

      <PrivateRoute path="/parceiros" exact component={Parceiro} />
      <PrivateRoute path="/parceiros/novo" exact component={NovoParceiro} />
      <PrivateRoute path="/parceiros/editar/:id" exact component={EditarParceiro} />
    </Switch>
  );
}