import React, { Component } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NovoUsuario from "./novoUsuario";
import EditarUsuario from "./editarUsuario";
import Erros from '../../components/Erro';

class Usuario extends Component {
    state = {
        localizarNome: "",
        usuarios: []
    }

    async componentDidMount() {
        await api.get('/Usuario/todos').then(response => {
            this.setState({ usuarios: response ? response.data : null });
        });
    }

    handleInputLocalizarNome = e => {
        this.setState({ localizarNome: e.currentTarget.value });
    }

    handleLocalizar = async e => {
        e.preventDefault();
        await api.get('/Usuario/nome/' + encodeURIComponent(this.state.localizarNome === '' ? '%' : this.state.localizarNome)).then(response => {
            this.setState({ usuarios: response ? response.data : null });
        });
    }

    handleExcluir = async id => {
        if (window.confirm('Dejesa realmente excluir?')) {
            await api.delete('/Usuario?id=' + id).then(response => {
                if (response) {
                    this.setState({ usuarios: this.state.usuarios.filter(u => u.id !== id) });
                }
            });
        }
    }

    render() {
        const { localizarNome, usuarios } = this.state;
        return (
            <div className="container" style={{ marginTop: 20 }}>
                <div className="card">
                    <div className="card-header">Usuários</div>
                    <Erros />
                    <div className="card-body">
                        <h5 className="card-title">Localizar</h5>
                        <div>
                            <form onSubmit={this.handleLocalizar}>
                                <div className="form-row">
                                    <div className="col-md-4 pt-2">
                                        <label className="sr-only">Nome do Usário</label>
                                        <input type="text" onChange={this.handleInputLocalizarNome} value={localizarNome} className="form-control" id="inputLocalizar" placeholder="Nome do Usuário" />
                                    </div>
                                    <div className="col-md-1 pt-2">
                                        <div style={{ display: "inline-flex" }}>
                                            <button type="submit" className="btn btn-primary mx-sm-0 mb-2">Localizar</button>
                                            <Link className="btn btn-success mx-sm-4 ml-2 mb-2" to="/usuarios/novo">Novo</Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">E-Mail</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usuarios && usuarios.map(usuario => (
                                            <tr key={usuario.id}>
                                                <td>{usuario.nome}</td>
                                                <td>{usuario.email}</td>
                                                <td style={{ textAlign: "right" }}>
                                                    <div style={{ display: "inline-flex" }}>
                                                        <Link className="mx-sm-1 ml-2 mb-2" to={'/usuarios/editar/' + usuario.id}><FontAwesomeIcon className="text-primary ml-2" icon={faEdit} /></Link>
                                                        <button type="button" className="mx-sm-1 ml-2 mb-2" style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => this.handleExcluir(usuario.id)} ><FontAwesomeIcon className="text-danger ml-2" icon={faTrashAlt} /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Usuario, NovoUsuario, EditarUsuario };