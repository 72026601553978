import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import api from '../../../services/api';
import { useHistory, useParams } from "react-router-dom";
import Erros from '../../../components/Erro';

function NovoProduto() {
    let history = useHistory();
    let { idFornecedor } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [fornecedor, setFornecedor] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [subgrupos, setSubgrupos] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await api.get('/Fornecedor/id/' + idFornecedor).then(response => {
                setFornecedor(response ? response.data : null);
            });
            await api.get('/Grupo/todos').then(response => {
                setGrupos(response ? response.data : null);
            });
            await api.get('/SubGrupo/todos').then(response => {
                setSubgrupos(response ? response.data : null);
            });
        }
        fetchData();
    }, [idFornecedor]);

    const onSubmit = async data => {
        window.scrollTo(0, 0);
        await api.post('/Produto', data).then(response => {
            if (response) {
                history.push(`/produtos/${idFornecedor}`);
            }
        });
    };

    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="card">
                <div className="card-header">
                    <h5>{fornecedor ? fornecedor.razaoSocial : ""}</h5>
                    Produtos
                </div>
                <Erros />
                <div className="card-body">
                    <h5 className="card-title">Novo Produto</h5>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" defaultValue={idFornecedor} name="fornecedorId" ref={register} />
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Descrição</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="descricao" id="descricao" ref={register({ required: true })} placeholder="Descrição" />
                                    {errors.descricao && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Referência</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <input type="text" className="form-control" name="referencia" id="referencia" ref={register({ required: true })} placeholder="Regferência" />
                                    {errors.referencia && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Sexo</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="sexo" id="sexo" ref={register} placeholder="Sexo">
                                        <option value=""></option>
                                        <option value="M">Masculino</option>
                                        <option value="F">Feminino</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Grupo</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="grupoId" id="grupoId" ref={register({ required: true })} placeholder="Grupo">
                                        <option value=""></option>
                                        {
                                            grupos && grupos.map((grupo, index) => (
                                                <option key={index} value={grupo.id}>{grupo.nome}</option>
                                            ))
                                        }
                                    </select>
                                    {errors.grupoId && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Subgrupo</label>
                                <div className="col-sm-10" style={{ display: "inline-flex" }}>
                                    <select className="form-control" name="subgrupoId" id="subgrupoId" ref={register({ required: true })} placeholder="Subgrupo">
                                        <option value=""></option>
                                        {
                                            subgrupos && subgrupos.map((grupo, index) => (
                                                <option key={index} value={grupo.id}>{grupo.nome}</option>
                                            ))
                                        }
                                    </select>
                                    {errors.subgrupoId && <span className="text-danger ml-2">*</span>}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 pt-2">
                                    <div style={{ display: "inline-flex" }}>
                                        <button type="submit" className="btn btn-primary mb-2">Salvar</button>
                                        <Link className="btn btn-danger mx-sm-3 ml-2 mb-2" to={`/produtos/${idFornecedor}`}>Cancelar</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NovoProduto;